@charset 'UTF-8';

.bottom-sheet {
  --font-size: var(--font-size-body-extra-small);

  align-items: center;
  border-color: var(--color-midnight-blue-500);
  border-style: solid;
  border-width: 2px 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: var(--font-family-code-regular);
  font-size: var(--font-size);
  gap: calc(var(--gutter) / 2) calc(var(--gutter) * 1.5);
  line-height: var(--font-size);
  margin-bottom: calc(var(--gutter) / 2);
  margin-top: var(--gutter);
  padding-top: calc(var(--gutter) / 2);
  place-content: center center;
  text-shadow: var(--text-shadow-black);
  white-space: nowrap;
}

@media (width <= 1024px) {
  .bottom-sheet {
    column-gap: var(--gutter);
  }
}

.bottom-sheet-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

@media (width <= 540px) {
  .bottom-sheet {
    flex-direction: column;
  }
}

/* ----------------------------------------------- */

.bottom-sheet a {
  color: var(--color-white);
  text-decoration: none;
}

/* ----------------------------------------------- */
.bottom-sheet a.global-village-brandmark,
.bottom-sheet a.monogram {
  fill: var(--color-midnight-blue-050);
}

/* ----------------------------------------------- */
a.footer-link {
  color: var(--color-white);
}

a.footer-link:hover,
a.footer-link[aria-current="page"] {
  color: var(--color-electric-blue-400);
}

a.footer-link:active,
a.footer-link:focus {
  color: var(--color-electric-blue-400);
}
