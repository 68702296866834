@charset 'UTF-8';

ul.social {
  align-items: center;
  column-gap: calc(var(--gutter) / 4);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
  place-content: center flex-start;
}

ul.social.share-page {
  display: inline-flex;
}

.scholarly-article ul.social.share-page {
  margin-top: var(--gutter);
}

.social.share-buttons::before {
  color: var(--color-white);
  content: "Share";
  font-size: var(--font-size-body-small);
}

ul.social li,
/* stylelint-disable-next-line no-descending-specificity */
ul.social.share-page {
  --social-icon-size: 24px;
}

ul.social li {
  align-self: auto;
  aspect-ratio: 1 / 1;
  flex-basis: var(--social-icon-size);
  flex-grow: 0;
  flex-shrink: 0;
  height: var(--social-icon-size);
  position: relative;
  width: var(--social-icon-size);
}

ul.social a,
ul.social button {
  --size: 100%;
  --surface-color: var(--color-green-700);
  --on-surface-color: var(--color-white);

  align-items: center;
  background-color: var(--surface-color);
  clip-path: polygon(25% 0%, 75% 0%, 100% 25%, 100% 75%, 75% 100%, 25% 100%, 0% 75%, 0% 25%);
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: var(--size);
  place-content: center center;
  text-decoration: none;
  transition-duration: var(--speed-fast), var(--speed-extra-fast);
  transition-property: background-color, color;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity);
  width: var(--size);
}

ul.social svg {
  --size: calc(100% - 10px);
  --position-xy: calc(50% - (var(--size) / 2));

  aspect-ratio: 1 / 1;
  fill: currentcolor;
  height: var(--size);
  left: var(--position-xy);
  position: absolute;
  top: var(--position-xy);
  width: var(--size);
}

/* ----------------------------------------------- */
ul.social a:hover,
ul.social button:hover {
  --surface-color: var(--color-green-200);
  --on-surface-color: var(--color-green-1000);

  transition-timing-function: var(--easing-entering), var(--easing-entering);
}

ul.social a:active,
ul.social a:focus,
ul.social button:active,
ul.social button:focus {
  --surface-color: var(--color-green-800);
  --on-surface-color: var(--color-white);

  transition-property: none;
}

/* ----------------------------------------------- */
.rss-feed-with-share-page {
  align-items: center;
  column-gap: calc(var(--gutter) * 1);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: calc(var(--gutter) * -1);
  place-content: center flex-end;
}

.rss-feed-with-share-page-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

/* ----------------------------------------------- */
@media (width <= 768px) {
  .rss-feed-with-share-page {
    justify-content: flex-start;
  }
}

@media (width <= 540px) {
  .rss-feed-with-share-page {
    margin-top: unset;
  }
}

/* ----------------------------------------------- */
.rss-feed-link,
/* stylelint-disable-next-line no-descending-specificity */
.rss-feed-link svg {
  --rss-icon-size: 14px;
}

.rss-feed-link {
  --surface-color: var(--color-lemon-yellow-900);
  --on-surface-color: var(--color-white);
  --font-size: var(--font-size-body-small);

  align-items: center;
  background-color: var(--surface-color);
  border-radius: var(--rounded-corners-small);
  box-shadow: var(--shadow-elevation-low);
  color: var(--on-surface-color);
  column-gap: calc(var(--rss-icon-size) / 2);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-code-bold);
  font-size: var(--font-size);
  line-height: var(--rss-icon-size);

  /* Optically align */
  margin-top: -2px;
  padding: var(--rounded-corners-small);
  place-content: center flex-start;
  text-shadow: none;
  transition-duration: var(--speed-fast), var(--speed-fast), var(--speed-extra-fast);
  transition-property: background-color, box-shadow, color;
  transition-timing-function:
    var(--easing-color-or-opacity), var(--easing-color-or-opacity), var(--easing-color-or-opacity);
  white-space: nowrap;
}

/* stylelint-disable-next-line no-descending-specificity */
.rss-feed-link svg {
  align-self: auto;
  fill: currentcolor;
  flex-basis: var(--rss-icon-size);
  flex-grow: 0;
  flex-shrink: 0;
  width: var(--rss-icon-size);
}

/* ----------------------------------------------- */
.rss-feed-link:hover {
  --surface-color: var(--color-lemon-yellow-500);
  --on-surface-color: var(--color-black);

  box-shadow: var(--shadow-elevation-medium);
}

.rss-feed-link:active,
.rss-feed-link:focus {
  --surface-color: var(--color-lemon-yellow-900);
  --on-surface-color: var(--color-white);

  box-shadow: none;
  transition-property: none;
}

/* ----------------------------------------------- */
.button-list {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: calc(var(--gutter) / 2) var(--gutter);
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: calc(var(--gutter) * 1);
  padding: 0;
  place-content: center flex-start;
}

.button-list[data-align="center"] {
  justify-content: center;
}

/* stylelint-disable-next-line no-descending-specificity */
.button-list li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
}

/* ----------------------------------------------- */
.countdown-timer {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: var(--gutter);
  place-content: center center;
  row-gap: calc(var(--gutter) / 2);
  text-align: center;
}

.countdown-timer-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.countdown-timer-cell-message {
  flex-basis: 100%;
}

.countdown-timer .header {
  margin-bottom: 0;
  margin-top: 0;
}

.countdown-clock,
.countdown-clock dd {
  --font-size: var(--font-size-h4);
}

@media (width <= 480px) {
  .countdown-clock,
  .countdown-clock dd {
    --font-size: var(--font-size-h5);
  }
}

.countdown-clock {
  align-items: center;
  column-gap: calc(var(--font-size) * 0.25);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-code-regular);
  font-size: var(--font-size);
  line-height: var(--font-size);
  margin: 0;
  padding: 0;
  place-content: center center;
  user-select: none;
}

.countdown-clock dt {
  display: none;
}

.countdown-clock dd {
  --surface-color: var(--color-black);
  --on-surface-color: var(--color-electric-blue-400);

  align-self: auto;
  background-color: var(--surface-color);
  border-radius: var(--rounded-corners-small);
  box-shadow: var(--shadow-elevation-low);
  color: var(--on-surface-color);
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  letter-spacing: var(--letter-spacing-all-caps);
  line-height: calc(var(--font-size) * 1.5);
  padding-bottom: calc(var(--font-size) * 0.05);

  /* padding-top: calc(var(--font-size) * 0.25); */
  position: relative;

  /* text-shadow: var(--text-shadow-black); */
  text-transform: uppercase;
  width: calc(var(--font-size) * 2.2);
}

.countdown-clock dd::after {
  --font-size: calc(var(--font-size-body-extra-small) * 0.85);

  bottom: calc(var(--font-size) * 2 * -1);
  color: var(--color-white);
  content: attr(data-units);
  font-family: var(--font-family-code-regular);
  font-size: var(--font-size);
  left: 0;
  letter-spacing: 0;
  line-height: var(--font-size);
  position: absolute;
  text-align: center;
  text-shadow: none;
  width: 100%;
}
