/* stylelint-disable declaration-no-important */
@charset 'UTF-8';

/*
  Custom CSS Reset
*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

[data-document-ready="false"] * {
  transition: none !important;
}

html,
body {
  height: 100%;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/* #root {
  isolation: isolate;
} */

/*
  End Custom CSS Reset
*/

/* ----------------------------------------------- */
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

img,
embed,
svg,
object,
video {
  border: 0;
  content-visibility: auto;
  display: inline-block;
  height: auto;

  /* image-rendering: high-quality; */
  max-width: 100%;
  overflow: hidden;
}

img {
  width: 100%;
}

img,
svg {
  display: block;
  position: relative;
}

.img-eager {
  content-visibility: visible;
}

/* ----------------------------------------------- */
circle,
ellipse,
line,
path,
polygon,
polyline,
rect {
  /* shape-rendering: optimizespeed; */
  shape-rendering: geometricprecision;
}

rect {
  shape-rendering: crispedges;
}

/* ----------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl,
legend {
  display: inherit;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  line-height: var(--type-scale-perfect-fourth);
  margin-bottom: 0;
  margin-top: 0;
  text-rendering: geometricprecision;
}

address {
  font-style: normal;
}

a {
  text-decoration: none;
}

a > img,
a > svg,
a:has(img),
a:has(svg),
button > img,
button > svg,
button:has(img),
button:has(svg) {
  display: inline-flex;
  outline: none !important;
}

/* ----------------------------------------------- */
/* stylelint-disable-next-line no-descending-specificity */
button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  background-image: none;
  border: none;
  cursor: pointer;
  outline-color: transparent;
  padding: 0;
}

a:focus,
input:focus {
  /* --outline-width: 1px;

  outline-color: var(--color-electric-blue-500);
  outline-offset: calc(var(--outline-width) * 4);
  outline-style: solid;
  outline-width: var(--outline-width); */
  outline: none;
}

/* ----------------------------------------------- */

.hide {
  /* employed for accessibility purposes */
  border-width: 0 !important;
  font-size: 0 !important;
  left: -9999em;
  margin: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute;
  text-indent: 100% !important;
  white-space: nowrap !important;
  z-index: -100;
}

/* ----------------------------------------------- */
:root {
  --color-neutral-900: #201e22;
  --color-neutral-800: #413e43;
  --color-neutral-700: #605d62;
  --color-neutral-600: #747176;
  --color-neutral-500: #9d999f;
  --color-neutral-400: #bcb9be;
  --color-neutral-300: #e0dce2;
  --color-neutral-200: #eeebf1;
  --color-neutral-100: #f6f3f9;
  --color-neutral-050: #fcf8fe;
  --easing-point-to-point: ease-in-out;
  --easing-entering: ease-out;
  --easing-exiting: ease-in;
  --easing-color-or-opacity: linear;
  --easing-snap: cubic-bezier(0, 0.8, 0.2, 1);
  --letter-spacing-tight: -0.025em;
  --letter-spacing-all-caps: 0.055em;
  --rounded-corners-extra-extra-large: 24px;
  --rounded-corners-extra-large: 20px;
  --rounded-corners-large: 10px;
  --rounded-corners-medium: 8px;
  --rounded-corners-small: 4px;
  --shadow-color: 0deg 0% 0%;
  --shadow-elevation-low: 0 1px 1.1px hsl(var(--shadow-color) / 32%),
    0 4px 4.5px -2.5px hsl(var(--shadow-color) / 32%);
  --shadow-elevation-medium: 0 1px 1.1px hsl(var(--shadow-color) / 20%),
    0.1px 5.2px 5.9px -1.2px hsl(var(--shadow-color) / 20%),
    0.2px 20px 22.5px -2.5px hsl(var(--shadow-color) / 20%);
  --shadow-elevation-high: 0 1px 1.1px hsl(var(--shadow-color) / 16%),
    0.1px 9.5px 10.7px -0.6px hsl(var(--shadow-color) / 16%),
    0.3px 22.7px 25.5px -1.2px hsl(var(--shadow-color) / 16%),
    0.6px 49.7px 55.9px -1.9px hsl(var(--shadow-color) / 16%),
    1.2px 100px 112.5px -2.5px hsl(var(--shadow-color) / 16%);
  --speed-extra-slow: 500ms;
  --speed-slow: 400ms;
  --speed-normal: 300ms;
  --speed-fast: 200ms;
  --speed-extra-fast: 100ms;
  --speed-super-fast: 10ms;
  --type-scale-octave: 2;
  --type-scale-golden-ratio: 1.618;
  --type-scale-perfect-fourth: 1.333;
  --type-scale-major-second: 1.125;
}

/* stylelint-disable declaration-no-important */
@charset 'UTF-8';

:root {
  --animation-delay-short: 0.1s;
  --animation-delay-medium: 0.5s;
  --color-black: black;
  --color-white: white;
  --color-neutral-900: #212121;
  --color-neutral-800: #424242;
  --color-neutral-700: #616161;
  --color-neutral-600: #757575;
  --color-neutral-500: #9e9e9e;
  --color-neutral-400: #bdbdbd;
  --color-neutral-300: #e0e0e0;
  --color-neutral-200: #eee;
  --color-neutral-100: whitesmoke;
  --color-neutral-050: #fafafa;

  /* Base: 900 */
  --color-midnight-blue-900: #12264b;
  --color-midnight-blue-800: #1a3663;
  --color-midnight-blue-700: #203f70;
  --color-midnight-blue-600: #28497b;
  --color-midnight-blue-500: #2f5083;
  --color-midnight-blue-400: #4f6893;
  --color-midnight-blue-300: #6e82a4;
  --color-midnight-blue-200: #96a5be;
  --color-midnight-blue-100: #bfc8d9;
  --color-midnight-blue-050: #e6e9ef;
  --color-electric-blue-900: #00656c;
  --color-electric-blue-800: #008898;
  --color-electric-blue-700: #009cb1;
  --color-electric-blue-600: #00b1cb;
  --color-electric-blue-500: #00c1df;

  /* Base: 400 */
  --color-electric-blue-400: #09cae4;
  --color-electric-blue-300: #46d4ea;
  --color-electric-blue-200: #7ee1f1;
  --color-electric-blue-100: #b2edf6;
  --color-electric-blue-050: #e0f8fc;
  --color-lemon-yellow-900: #fe7f00;
  --color-lemon-yellow-800: #fa0;
  --color-lemon-yellow-700: #fec302;
  --color-lemon-yellow-600: #ffdc14;

  /* Base: 500 */
  --color-lemon-yellow-500: #fff01d;
  --color-lemon-yellow-400: #f9ed43;
  --color-lemon-yellow-300: #fcf26a;
  --color-lemon-yellow-200: #fef696;
  --color-lemon-yellow-100: #fefac1;
  --color-lemon-yellow-050: #fffee6;
  --color-ruby-red-900: #7e0033;
  --color-ruby-red-800: #a00037;

  /* Base: 700 */
  --color-ruby-red-700: #b30838;
  --color-ruby-red-600: #c7103b;
  --color-ruby-red-500: #d6173b;
  --color-ruby-red-400: #de3755;
  --color-ruby-red-300: #e75971;
  --color-ruby-red-200: #f08898;
  --color-ruby-red-100: #f7b6c1;
  --color-ruby-red-050: #fce2e6;
  --color-green-1000: #002e1b;
  --color-green-900: #005532;
  --color-green-800: #007448;
  --color-green-700: #008555;
  --color-green-600: #009762;
  --color-green-500: #00a66d;

  /* Base: 400 */
  --color-green-400: #08b383;
  --color-green-300: #51c098;
  --color-green-200: #89d1b4;
  --color-green-100: #b8e3d1;
  --color-green-050: #e2f4ed;
  --window-shade: linear-gradient(
    180deg,
    var(--color-midnight-blue-900) 0%,
    rgb(255 255 255 / 0%) 100%
  );
  --font-family-serif-medium: "eiko/medium", serif;
  --font-family-code-light: "IBMPlexMono/light", system-ui, helvetica, arial, sans-serif;
  --font-family-code-medium: "IBMPlexMono/medium", system-ui, helvetica, arial, sans-serif;
  --font-family-code-regular: "IBMPlexMono/regular", system-ui, helvetica, arial, sans-serif;
  --font-family-code-bold: "IBMPlexMono/bold", system-ui, helvetica, arial, sans-serif;
  --font-family-code-semi-bold: "IBMPlexMono/semi-bold", system-ui, helvetica, arial, sans-serif;
  --font-family-code-italic: "IBMPlexMono/italic", system-ui, helvetica, arial, sans-serif;
  --font-family-code-medium-italic:
    "IBMPlexMono/medium-italic", system-ui, helvetica, arial, sans-serif;
  --font-size-h1: 96px;
  --font-size-h2: 60px;
  --font-size-h3: 48px;
  --font-size-h4: 34px;
  --font-size-h5: 25px;
  --font-size-h6: 20px;
  --font-size-body: 16px;
  --font-size-body-big: 23px;
  --font-size-body-small: 14px;
  --font-size-body-extra-small: 12px;
  --font-size-h1-fluid: clamp(3.355rem, 2.64rem + 3.586vw, 6.941rem);
  --font-size-h2-fluid: clamp(2.684rem, 2.178rem + 2.53vw, 5.214rem);
  --font-size-h3-fluid: clamp(2.145rem, 1.793rem + 1.76vw, 3.905rem);
  --font-size-h4-fluid: clamp(1.716rem, 1.474rem + 1.21vw, 2.936rem);
  --font-size-h5-fluid: clamp(1.375rem, 1.21rem + 0.825vw, 2.2rem);
  --font-size-h6-fluid: clamp(1.1rem, 0.99rem + 0.55vw, 1.65rem);
  --font-size-body-fluid: clamp(0.88rem, 0.814rem + 0.363vw, 1.243rem);
  --font-size-body-big-fluid: clamp(0.968rem, 0.8954rem + 0.3993vw, 1.3673rem);
  --font-size-body-small-fluid: clamp(0.704rem, 0.66rem + 0.22vw, 0.924rem);
  --gutter: 2rem;
  --text-shadow-white: hsl(0deg 0% 100% / 75%) 0 1px 1px;
  --text-shadow-black: hsl(0deg 0% 0% / 75%) 0 1px 1px;
  --text-shadow-electric-blue: hsl(0deg 0% 0% / 75%) 0 1px 1px;
  --text-shadow-button-primary: hsl(349deg 100% 13% / 75%) 0 1px 1px;
}

@font-face {
  font-display: fallback;
  font-family: "eiko/medium";
  src: url("/fonts/eiko/medium.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "IBMPlexMono/bold";
  src: url("/fonts/IBMPlexMono/bold.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "IBMPlexMono/semi-bold";
  src: url("/fonts/IBMPlexMono/semi-bold.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "IBMPlexMono/medium";
  src: url("/fonts/IBMPlexMono/medium.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "IBMPlexMono/medium-italic";
  src: url("/fonts/IBMPlexMono/medium-italic.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "IBMPlexMono/regular";
  src: url("/fonts/IBMPlexMono/regular.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "IBMPlexMono/italic";
  src: url("/fonts/IBMPlexMono/italic.woff2") format("woff2");
}

[data-document-ready="false"] *,
[data-document-ready="false"] *::after,
[data-document-ready="false"] *::before {
  animation-play-state: paused !important;
}

@media (prefers-reduced-motion) {
  [data-document-ready="true"] *,
  [data-document-ready="true"] *::after,
  [data-document-ready="true"] *::before {
    animation-play-state: paused !important;
  }
}

body {
  background-color: var(--color-midnight-blue-900);
  color: var(--color-white);
  font-family: var(--font-family-code-regular);
  font-size: var(--font-size-body);
}

/* ----------------------------------------------- */
.application-shell {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100%;
  place-content: center flex-start;
}

.application-shell-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.application-shell-cell-header,
.application-shell-cell-body,
.application-shell-cell-footer {
  width: 100%;
}

.application-shell-cell-body,
.application-shell-cell-footer {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.application-shell-cell-body {
  /* align-content: center; */
  align-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
}

.application-shell-cell-body.home {
  align-content: center;
  padding-bottom: 2vh;
}

.application-shell-cell-body.align-left {
  text-align: left;
}

/* ----------------------------------------------- */
.single-type,
.single-type-cell {
  --column-gap: calc(var(--gutter) * 2);
}

@media (width <= 768px) {
  .single-type,
  .single-type-cell {
    --column-gap: var(--gutter);
  }
}

.single-type {
  align-items: stretch;
  -moz-column-gap: var(--column-gap);
       column-gap: var(--column-gap);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  max-width: 1024px;
  place-content: stretch flex-start;
}

.single-type-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.single-type-cell-content {
  --default-width: 66.6%;

  flex-basis: calc(var(--default-width) - var(--column-gap));
  order: 1;
}

.single-type-cell-hero-media {
  --min-width: 100px;
  --max-width: 220px;
  --preferred-width: calc((var(--min-width) + var(--max-width)) / 2);

  flex-basis: calc(var(--preferred-width) - var(--column-gap));
  max-width: var(--max-width);
  min-width: var(--min-width);
  order: 0;
}

/* ----------------------------------------------- */
.single-type .hero-media-container {
  aspect-ratio: 1 / 1;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  place-items: center;
  position: relative;
}

main.single-type {
  padding-bottom: calc(var(--gutter) * 2);
}

@media (width <= 768px) {
  .single-type-cell-content {
    --default-width: 75%;
  }
}

@media (width <= 540px) {
  .single-type {
    flex-direction: column;
    margin-top: var(--gutter);
  }

  .single-type-cell-hero-media {
    --max-width: unset;

    flex-basis: 100%;
    padding-bottom: var(--gutter);
  }

  .single-type .hero-media-container {
    max-width: 180px;
  }
}

@media (width <= 414px) {
  .single-type-cell-hero-media {
    display: none;
  }

  .single-type {
    margin-top: 0;
  }

  .article-date-publisher-and-doi-container-cell-publisher-logo {
    display: none;
  }
}

/* ----------------------------------------------- */
.button-unstyled,
.button-unstyled:hover,
.button-unstyled:active,
.button-unstyled:focus {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  transition-property: none;
}

/* ----------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
legend {
  /* font-family: var(--font-family-serif-medium); */
  font-family: var(--font-family-code-medium);
  text-shadow: var(--text-shadow-black);
}

h2,
h3,
h4,
h5,
h6,
legend,
p,
ul,
ol,
hr {
  --vertical-margin: var(--gutter);
}

h1 {
  font-size: var(--font-size-h4-fluid);
  line-height: var(--type-scale-major-second);
}

h2 {
  color: var(--color-electric-blue-300);
  font-size: var(--font-size-h5-fluid);
  margin-top: var(--vertical-margin);
}

legend,
p.header {
  font-size: var(--font-size-h6-fluid);
  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
}

p,
ul,
ol,
dl {
  font-family: var(--font-family-code-regular);
  font-size: var(--font-size-body-fluid);
  line-height: var(--type-scale-golden-ratio);
  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
}

strong {
  color: var(--color-electric-blue-400);
  font-family: var(--font-family-code-bold);
  font-style: normal;
}

em {
  font-family: var(--font-family-code-italic);
  font-style: normal;
}

/* ----------------------------------------------- */
p.lead-paragraph,
.single-type-cell-content {
  text-shadow: var(--text-shadow-black);
}

p.lead-paragraph {
  font-family: var(--font-family-code-medium);
  font-size: var(--font-size-body-big-fluid);
}

@media (width <= 480px) {
  p.lead-paragraph {
    text-align: unset;
  }
}

/* ----------------------------------------------- */
.level-1-heading-container-with-sharing-options {
  --gap: var(--gutter);

  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: calc(var(--gutter) / 2) var(--gutter);
  place-content: flex-start flex-start;
}

.level-1-heading-container-with-sharing-options .cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  order: 1;
  width: 100%;
}

.level-1-heading-container-with-sharing-options .sharing-options {
  order: 0;
}

.level-1-heading-container-with-sharing-options .social.share-buttons::before {
  display: none;
}

/* ----------------------------------------------- */
hr {
  background-color: var(--color-midnight-blue-500);
  border: none;
  height: 3px;
  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
  padding: 0;
  width: 100%;
}

/* ----------------------------------------------- */
a,
button {
  cursor: pointer;
}

/* ----------------------------------------------- */
a[aria-current="page"] {
  pointer-events: none;
}

/* ----------------------------------------------- */
a.inline-text-link,
.text-link-with-illustration .label {
  --decoration-height: 2px;
  --decoration-color: var(--color-electric-blue-400);

  background-color: transparent;
  background-image: linear-gradient(
    to right,
    var(--decoration-color) 0,
    var(--decoration-color) 100%
  );
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 0 var(--decoration-height);
  color: var(--color-electric-blue-300);
  display: inline;
  padding-bottom: calc(var(--decoration-height) * 2);
  padding-top: var(--decoration-height);
  transition-duration: var(--speed-fast);
  transition-property: background-size;
  transition-timing-function: var(--easing-exiting);
}

a.inline-text-link[data-color-scheme="yellow"],
.text-link-with-illustration[data-color-scheme="yellow"] .label {
  --decoration-color: var(--color-lemon-yellow-500);

  color: var(--color-lemon-yellow-500);
}

a.inline-text-link:hover,
.text-link-with-illustration:hover .label {
  background-size: 100% var(--decoration-height);
}

a.inline-text-link:active,
a.inline-text-link:focus,
.text-link-with-illustration:active .label,
.text-link-with-illustration:focus .label {
  --decoration-color: transparent;

  background-color: var(--color-black);
  color: var(--color-white);
}

/* ----------------------------------------------- */
.spirit-analyzer-logo-container {
  --min-width: 152px;
  --max-width: 375px;
  --preferred-width: 100%;
  --freespace: calc(var(--gutter) / 3);

  align-items: center;
  background-color: var(--color-midnight-blue-900);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: calc(var(--freespace) * -1);
  padding: var(--freespace);
  place-content: center center;
  width: clamp(var(--min-width), var(--preferred-width), var(--max-width));
}

.spirit-analyzer-logo {
  --descriptor-color: var(--color-white);

  width: 100%;
}

.spirit-analyzer-logo .blue {
  /* fill: #abd5f2; */

  /* fill: var(--color-electric-blue-050); */
  fill: var(--color-white);
}

.spirit-analyzer-logo .red {
  fill: var(--color-ruby-red-700);
}

.spirit-analyzer-logo .text {
  fill: var(--descriptor-color);
}

@media (width <= 414px) {
  .spirit-analyzer-logo-container {
    margin-left: 0;
  }
}

/* ----------------------------------------------- */
.close-button,
.close-button svg {
  --on-surface-color: var(--color-neutral-200);
}

.close-button {
  --size: 36px;
  --surface-color: var(--color-black);

  background-color: var(--surface-color);
  border-radius: 100%;
  color: var(--on-surface-color);
  display: grid;
  height: var(--size);
  line-height: unset;
  place-items: center center;
  width: var(--size);
}

.subscribe-sheet .close-button {
  position: absolute;
  right: calc(var(--gutter) / 2);
}

.close-button svg {
  --icon-size: 100%;

  fill: currentcolor;
  height: var(--icon-size);
  transform-origin: center;
  transition-duration: var(--speed-normal);
  transition-property: transform;
  transition-timing-function: var(--easing-snap);
  width: var(--icon-size);
}

.close-button:hover svg,
.close-button:active svg,
.close-button:focus svg {
  --on-surface-color: var(--color-white);
}

.close-button:hover svg {
  transform: scale(0.8);
  transition-duration: var(--speed-extra-fast);
}

.close-button:active svg,
.close-button:focus svg {
  transform: none;
  transition-property: none;
}

.close-button:focus {
  outline: none;
}

@charset 'UTF-8';

nav.top-app-bar {
  margin-bottom: var(--gutter);
  margin-top: calc(var(--gutter) / 2);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  position: relative;
}

.top-app-bar a::before,
.top-app-bar ul,
.top-app-bar li {
  --font-size: var(--font-size-body-big-fluid);
}

.top-app-bar li.brandmark-row {
  flex-basis: 100%;
  position: relative;
}

.top-app-bar ul.nested-top-app-bar-menu,
.top-app-bar ul.nested-top-app-bar-menu li,
.top-app-bar ul.nested-top-app-bar-menu a::before,
.top-app-bar li.top-app-bar-utilities,
.top-app-bar li.top-app-bar-utilities a::before {
  --font-size: var(--font-size-body-small);
}

.top-app-bar li.top-app-bar-utilities,
.top-app-bar ul.nested-top-app-bar-menu,
.top-app-bar li.top-app-bar-utilities em,
.top-app-bar ul.nested-top-app-bar-menu em {
  font-family: var(--font-family-code-medium);
}

.top-app-bar li.top-app-bar-utilities {
  flex-basis: auto;
  flex-shrink: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.top-app-bar li.top-app-bar-utilities.home {
  left: 0;
  right: unset;
}

/* ----------------------------------------------- */
.top-app-bar .link,
.top-app-bar .link::after {
  --decoration-height: 2px;
}

.top-app-bar .link {
  color: var(--color-white);
  display: block;
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
  padding-bottom: var(--decoration-height);
  position: relative;
  text-decoration: none;
  text-shadow: var(--text-shadow-black);
}

.top-app-bar .top-app-bar-utilities .link,
.top-app-bar .top-app-bar-utilities .link::after {
  --decoration-height: 0;
}

.top-app-bar .top-app-bar-utilities .link {
  align-items: center;
  -moz-column-gap: calc(var(--font-size) / 2);
       column-gap: calc(var(--font-size) / 2);
  display: inline-flex;
}

.top-app-bar .link:hover {
  text-shadow: none;
}

.top-app-bar .link[aria-current="page"] {
  color: var(--color-white);
}

.top-app-bar .link.primary-link {
  display: block;
  font-family: var(--font-family-code-medium);
  letter-spacing: var(--letter-spacing-all-caps);
  margin-left: auto;
  margin-right: auto;
  max-width: 200px;
  text-transform: uppercase;
  width: 100%;
}

@media (width <= 768px) {
  .top-app-bar ul {
    /* stylelint-disable-next-line declaration-no-important */
    --horizontal-padding: 0 !important;
  }
}

/* ----------------------------------------------- */

.top-app-bar .link::before {
  --height: calc(var(--font-size) * 2);
  --width: calc(100% + var(--font-size) * 1);
  --position-y: calc(50% - (var(--height) / 2));
  --position-x: calc(50% - (var(--width) / 2));

  background-color: var(--color-midnight-blue-700);
  border-color: transparent;
  border-radius: var(--rounded-corners-medium);
  border-style: solid;
  border-width: 2px;
  content: "";
  height: var(--height);
  left: var(--position-x);
  position: absolute;
  top: calc(var(--position-y) - var(--decoration-height) / 3);
  transition-duration: var(--speed-fast);
  transition-property: background-color;
  transition-timing-function: var(--easing-color-or-opacity);
  width: var(--width);
  z-index: -1;
}

.top-app-bar .top-app-bar-utilities .link::before {
  --height: calc(var(--font-size) * 2.4);

  background-color: var(--color-midnight-blue-500);
  border-width: 1px;
  box-shadow: var(--shadow-elevation-low);
}

.top-app-bar .link:hover::before,
.top-app-bar .link[aria-current="page"]::before {
  background-color: var(--color-black);
  box-shadow: var(--shadow-elevation-medium);
}

.top-app-bar .link:hover::before {
  box-shadow: var(--shadow-elevation-high);
}

.top-app-bar .link:active::before {
  box-shadow: var(--shadow-elevation-low);
}

.top-app-bar .link:active::before,
.top-app-bar .link:focus::before,
.top-app-bar .link[aria-current="page"]::before {
  border-color: var(--color-electric-blue-500);
  transition-property: none;
}

.top-app-bar .link.meeting:active::before,
.top-app-bar .link.meeting:focus::before,
.top-app-bar .link.meeting[aria-current="page"]::before {
  border-color: var(--color-lemon-yellow-500);
}

.top-app-bar .link.about:active::before,
.top-app-bar .link.about:focus::before,
.top-app-bar .link.about[aria-current="page"]::before {
  border-color: var(--color-ruby-red-700);
}

/* ----------------------------------------------- */
.brandmark {
  --brandmark-width: 168px;

  fill: var(--color-white);
  margin-left: auto;
  margin-right: auto;
  margin-top: calc(var(--gutter) / 4);
  overflow: visible;
  position: relative;
  width: var(--brandmark-width);
}

@media (width <= 768px) {
  .brandmark {
    --brandmark-width: 152px;
  }
}

.brandmark::after {
  --circuits-height: calc(var(--brandmark-width) / 5);
  --circuits-width: calc(var(--brandmark-width) / 1.75);

  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 85.3"><path d="M256 5.7c0 3.2-2.6 5.7-5.7 5.7s-4.9-1.9-5.6-4.4h-101l-35.6 26.9 3.2 4.6H192c.5-2.6 2.8-4.6 5.6-4.6s5.7 2.6 5.7 5.7-2.6 5.7-5.7 5.7-5.1-1.9-5.6-4.5l-80.7-.2-4.1-6-15.3 11.5h-1.7l7.2 10.5 55.5-.6c.4-2.8 2.8-4.9 5.7-4.9s5.7 2.6 5.7 5.7-2.6 5.7-5.7 5.7-5.3-2.1-5.7-4.9H98.1l14.2 20.7h103.1c.5-2.6 2.8-4.6 5.6-4.6s5.7 2.6 5.7 5.7-2.6 5.7-5.7 5.7-5.1-1.9-5.6-4.5H112.2L96.6 57.5H4l91.9-1L88.8 46 0 45.5l87.8-.7-10.2-15H4l74.6-.8 10.8 15.8h2.7l14.5-11.4L88.3 6.8H4.1l84.6-1 18.6 26.9 36.3-28.5h101.1c.6-2.5 2.9-4.3 5.5-4.3s5.7 2.6 5.7 5.7z" fill="%237ee1f1"/></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  bottom: calc(var(--circuits-height) / 1.6 * -1);
  content: "";
  height: var(--circuits-height);
  left: calc(var(--circuits-width) / 1.5 * -1);
  position: absolute;
  scale: 1.3;
  width: var(--circuits-width);
}

.brandmark .monoclonal-antibody {
  fill: var(--color-ruby-red-700);
}

.brandmark-row .descriptor {
  display: block;
  font-family: var(--font-family-code-semi-bold);
  font-size: var(--font-size-body-fluid);
  line-height: var(--type-scale-golden-ratio);
  margin-top: calc(var(--gutter) * 1.25);
  text-shadow: var(--text-shadow-black);
  white-space: nowrap;
}

.brandmark-row .descriptor,
/* stylelint-disable-next-line no-descending-specificity */
.brandmark-row .descriptor em {
  font-family: var(--font-family-code-semi-bold);
  font-style: normal;
}

@media (width <= 768px) {
  .top-app-bar ul {
    /* stylelint-disable-next-line declaration-no-important */
    --horizontal-padding: 0 !important;
  }
}

@media (width <= 480px) {
  .brandmark {
    --brandmark-width: 128px;
  }

  .brandmark::after {
    display: none;
  }

  .brandmark-row .descriptor em {
    display: block;
  }

  .brandmark-row .descriptor {
    margin-top: calc(var(--gutter) * 0.5);
  }
}

/* ----------------------------------------------- */
.top-app-bar em.icon {
  --icon-size: 24px;

  aspect-ratio: 1 / 1;
  background-color: var(--color-midnight-blue-900);
  border-radius: 100%;
  display: grid;
  height: var(--icon-size);
  overflow: hidden;
  padding: 4px;
  place-content: center center;
  position: relative;
  width: var(--icon-size);
}

.top-app-bar .link:hover em.icon,
.top-app-bar .link[aria-current="page"] em.icon {
  background-color: var(--color-electric-blue-400);
  color: var(--color-black);
}

.top-app-bar em.icon svg {
  --size: 100%;

  display: block;
  fill: currentcolor;
  height: var(--size);
  margin-left: auto;
  margin-right: auto;
  width: var(--size);
}

/* ----------------------------------------------- */
@media (width <= 768px) {
  .top-app-bar li.top-app-bar-utilities {
    margin-right: calc(var(--gutter) / 2);
  }

  .top-app-bar li.top-app-bar-utilities.home {
    margin-left: calc(var(--gutter) / 2);
    margin-right: 0;
  }
}

@media (width <= 540px) {
  .top-app-bar ul:not(.nested-top-app-bar-menu) {
    --column-gap: calc(var(--gutter) * 1.5);

    justify-content: center;
  }

  .top-app-bar li[data-page="home"] {
    display: none;
  }

  .top-app-bar li.top-app-bar-utilities .icon {
    display: unset;
  }

  .top-app-bar li.top-app-bar-utilities .label {
    display: none;
  }
}

@media (width <= 480px) {
  .top-app-bar ul {
    row-gap: calc(var(--gutter) * 0.75);
  }

  nav.top-app-bar {
    --horizontal-padding: calc(var(--gutter) / 4);
  }

  .top-app-bar li.top-app-bar-utilities {
    right: calc(var(--gutter) / 4);
  }

  .top-app-bar li.top-app-bar-utilities.home {
    left: calc(var(--gutter) / 4);
  }
}

/* ----------------------------------------------- */
.top-app-bar ul.nested-top-app-bar-menu {
  align-items: center;
  -moz-column-gap: var(--gutter);
       column-gap: var(--gutter);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-code-medium);
  font-size: var(--font-size);
  margin-bottom: unset;
  margin-left: unset;
  margin-right: unset;
  margin-top: unset;
  max-width: unset;
  place-content: center flex-end;
}

.top-app-bar ul.nested-top-app-bar-menu li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.top-app-bar ul {
  font-size: var(--font-size);
}

@charset 'UTF-8';

.bottom-sheet {
  --font-size: var(--font-size-body-extra-small);

  align-items: center;
  border-color: var(--color-midnight-blue-500);
  border-style: solid;
  border-width: 2px 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: var(--font-family-code-regular);
  font-size: var(--font-size);
  gap: calc(var(--gutter) / 2) calc(var(--gutter) * 1.5);
  line-height: var(--font-size);
  margin-bottom: calc(var(--gutter) / 2);
  margin-top: var(--gutter);
  padding-top: calc(var(--gutter) / 2);
  place-content: center center;
  text-shadow: var(--text-shadow-black);
  white-space: nowrap;
}

@media (width <= 1024px) {
  .bottom-sheet {
    -moz-column-gap: var(--gutter);
         column-gap: var(--gutter);
  }
}

.bottom-sheet-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

@media (width <= 540px) {
  .bottom-sheet {
    flex-direction: column;
  }
}

/* ----------------------------------------------- */

.bottom-sheet a {
  color: var(--color-white);
  text-decoration: none;
}

/* ----------------------------------------------- */
.bottom-sheet a.global-village-brandmark,
.bottom-sheet a.monogram {
  fill: var(--color-midnight-blue-050);
}

/* ----------------------------------------------- */
a.footer-link {
  color: var(--color-white);
}

a.footer-link:hover,
a.footer-link[aria-current="page"] {
  color: var(--color-electric-blue-400);
}

a.footer-link:active,
a.footer-link:focus {
  color: var(--color-electric-blue-400);
}

@charset 'UTF-8';

ul.social {
  align-items: center;
  -moz-column-gap: calc(var(--gutter) / 4);
       column-gap: calc(var(--gutter) / 4);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
  place-content: center flex-start;
}

ul.social.share-page {
  display: inline-flex;
}

.scholarly-article ul.social.share-page {
  margin-top: var(--gutter);
}

.social.share-buttons::before {
  color: var(--color-white);
  content: "Share";
  font-size: var(--font-size-body-small);
}

ul.social li,
/* stylelint-disable-next-line no-descending-specificity */
ul.social.share-page {
  --social-icon-size: 24px;
}

ul.social li {
  align-self: auto;
  aspect-ratio: 1 / 1;
  flex-basis: var(--social-icon-size);
  flex-grow: 0;
  flex-shrink: 0;
  height: var(--social-icon-size);
  position: relative;
  width: var(--social-icon-size);
}

ul.social a,
ul.social button {
  --size: 100%;
  --surface-color: var(--color-green-700);
  --on-surface-color: var(--color-white);

  align-items: center;
  background-color: var(--surface-color);
  clip-path: polygon(25% 0%, 75% 0%, 100% 25%, 100% 75%, 75% 100%, 25% 100%, 0% 75%, 0% 25%);
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: var(--size);
  place-content: center center;
  text-decoration: none;
  transition-duration: var(--speed-fast), var(--speed-extra-fast);
  transition-property: background-color, color;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity);
  width: var(--size);
}

ul.social svg {
  --size: calc(100% - 10px);
  --position-xy: calc(50% - (var(--size) / 2));

  aspect-ratio: 1 / 1;
  fill: currentcolor;
  height: var(--size);
  left: var(--position-xy);
  position: absolute;
  top: var(--position-xy);
  width: var(--size);
}

/* ----------------------------------------------- */
ul.social a:hover,
ul.social button:hover {
  --surface-color: var(--color-green-200);
  --on-surface-color: var(--color-green-1000);

  transition-timing-function: var(--easing-entering), var(--easing-entering);
}

ul.social a:active,
ul.social a:focus,
ul.social button:active,
ul.social button:focus {
  --surface-color: var(--color-green-800);
  --on-surface-color: var(--color-white);

  transition-property: none;
}

/* ----------------------------------------------- */
.rss-feed-with-share-page {
  align-items: center;
  -moz-column-gap: calc(var(--gutter) * 1);
       column-gap: calc(var(--gutter) * 1);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: calc(var(--gutter) * -1);
  place-content: center flex-end;
}

.rss-feed-with-share-page-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

/* ----------------------------------------------- */
@media (width <= 768px) {
  .rss-feed-with-share-page {
    justify-content: flex-start;
  }
}

@media (width <= 540px) {
  .rss-feed-with-share-page {
    margin-top: unset;
  }
}

/* ----------------------------------------------- */
.rss-feed-link,
/* stylelint-disable-next-line no-descending-specificity */
.rss-feed-link svg {
  --rss-icon-size: 14px;
}

.rss-feed-link {
  --surface-color: var(--color-lemon-yellow-900);
  --on-surface-color: var(--color-white);
  --font-size: var(--font-size-body-small);

  align-items: center;
  background-color: var(--surface-color);
  border-radius: var(--rounded-corners-small);
  box-shadow: var(--shadow-elevation-low);
  color: var(--on-surface-color);
  -moz-column-gap: calc(var(--rss-icon-size) / 2);
       column-gap: calc(var(--rss-icon-size) / 2);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-code-bold);
  font-size: var(--font-size);
  line-height: var(--rss-icon-size);

  /* Optically align */
  margin-top: -2px;
  padding: var(--rounded-corners-small);
  place-content: center flex-start;
  text-shadow: none;
  transition-duration: var(--speed-fast), var(--speed-fast), var(--speed-extra-fast);
  transition-property: background-color, box-shadow, color;
  transition-timing-function:
    var(--easing-color-or-opacity), var(--easing-color-or-opacity), var(--easing-color-or-opacity);
  white-space: nowrap;
}

/* stylelint-disable-next-line no-descending-specificity */
.rss-feed-link svg {
  align-self: auto;
  fill: currentcolor;
  flex-basis: var(--rss-icon-size);
  flex-grow: 0;
  flex-shrink: 0;
  width: var(--rss-icon-size);
}

/* ----------------------------------------------- */
.rss-feed-link:hover {
  --surface-color: var(--color-lemon-yellow-500);
  --on-surface-color: var(--color-black);

  box-shadow: var(--shadow-elevation-medium);
}

.rss-feed-link:active,
.rss-feed-link:focus {
  --surface-color: var(--color-lemon-yellow-900);
  --on-surface-color: var(--color-white);

  box-shadow: none;
  transition-property: none;
}

/* ----------------------------------------------- */
.button-list {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: calc(var(--gutter) / 2) var(--gutter);
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: calc(var(--gutter) * 1);
  padding: 0;
  place-content: center flex-start;
}

.button-list[data-align="center"] {
  justify-content: center;
}

/* stylelint-disable-next-line no-descending-specificity */
.button-list li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
}

/* ----------------------------------------------- */
.countdown-timer {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: var(--gutter);
  place-content: center center;
  row-gap: calc(var(--gutter) / 2);
  text-align: center;
}

.countdown-timer-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.countdown-timer-cell-message {
  flex-basis: 100%;
}

.countdown-timer .header {
  margin-bottom: 0;
  margin-top: 0;
}

.countdown-clock,
.countdown-clock dd {
  --font-size: var(--font-size-h4);
}

@media (width <= 480px) {
  .countdown-clock,
  .countdown-clock dd {
    --font-size: var(--font-size-h5);
  }
}

.countdown-clock {
  align-items: center;
  -moz-column-gap: calc(var(--font-size) * 0.25);
       column-gap: calc(var(--font-size) * 0.25);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-code-regular);
  font-size: var(--font-size);
  line-height: var(--font-size);
  margin: 0;
  padding: 0;
  place-content: center center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.countdown-clock dt {
  display: none;
}

.countdown-clock dd {
  --surface-color: var(--color-black);
  --on-surface-color: var(--color-electric-blue-400);

  align-self: auto;
  background-color: var(--surface-color);
  border-radius: var(--rounded-corners-small);
  box-shadow: var(--shadow-elevation-low);
  color: var(--on-surface-color);
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  letter-spacing: var(--letter-spacing-all-caps);
  line-height: calc(var(--font-size) * 1.5);
  padding-bottom: calc(var(--font-size) * 0.05);

  /* padding-top: calc(var(--font-size) * 0.25); */
  position: relative;

  /* text-shadow: var(--text-shadow-black); */
  text-transform: uppercase;
  width: calc(var(--font-size) * 2.2);
}

.countdown-clock dd::after {
  --font-size: calc(var(--font-size-body-extra-small) * 0.85);

  bottom: calc(var(--font-size) * 2 * -1);
  color: var(--color-white);
  content: attr(data-units);
  font-family: var(--font-family-code-regular);
  font-size: var(--font-size);
  left: 0;
  letter-spacing: 0;
  line-height: var(--font-size);
  position: absolute;
  text-align: center;
  text-shadow: none;
  width: 100%;
}

@charset 'UTF-8';

[data-shape="rabbet"] {
  clip-path: polygon(
    0% 15%,
    15% 15%,
    15% 0%,
    85% 0%,
    85% 15%,
    100% 15%,
    100% 85%,
    85% 85%,
    85% 100%,
    15% 100%,
    15% 85%,
    0% 85%
  );
}

[data-shape="triangle"] {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

[data-shape="rhombus"] {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

[data-shape="circle"] {
  clip-path: circle(50% at 50% 50%);
}

[data-shape="message"] {
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
}

[data-shape="nonagon"] {
  clip-path: polygon(
    50% 0%,
    83% 12%,
    100% 43%,
    94% 78%,
    68% 100%,
    32% 100%,
    6% 78%,
    0% 43%,
    17% 12%
  );
}

[data-shape="bevel"] {
  clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
}

[data-shape="right-point"] {
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
}

[data-shape="trapezoid"] {
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
}

[data-shape="dna-sequence-paper"] {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 10% 90%, 90% 90%, 90% 10%, 10% 10%);
}

[data-shape="book"] {
  clip-path: polygon(0% 0%, 50% 5%, 100% 0%, 100% 100%, 50% 95%, 0% 100%);
}

[data-shape="virus-bacteria"] {
  clip-path: polygon(
    50% 0%,
    58% 18%,
    75% 15%,
    68% 33%,
    85% 50%,
    68% 67%,
    75% 85%,
    58% 82%,
    50% 100%,
    42% 82%,
    25% 85%,
    32% 67%,
    15% 50%,
    32% 33%,
    25% 15%,
    42% 18%
  );
}

[data-shape="dna-double-helix"] {
  clip-path: polygon(
    50% 0%,
    55% 10%,
    60% 0%,
    65% 10%,
    70% 0%,
    75% 10%,
    80% 0%,
    85% 10%,
    90% 0%,
    95% 10%,
    100% 0%,
    100% 100%,
    95% 90%,
    90% 100%,
    85% 90%,
    80% 100%,
    75% 90%,
    70% 100%,
    65% 90%,
    60% 100%,
    55% 90%,
    50% 100%,
    45% 90%,
    40% 100%,
    35% 90%,
    30% 100%,
    25% 90%,
    20% 100%,
    15% 90%,
    10% 100%,
    5% 90%,
    0% 100%,
    0% 0%,
    5% 10%,
    10% 0%,
    15% 10%,
    20% 0%,
    25% 10%,
    30% 0%,
    35% 10%,
    40% 0%,
    45% 10%
  );
}

[data-shape="antibody"] {
  clip-path: polygon(50% 0%, 55% 20%, 70% 20%, 65% 50%, 50% 40%, 35% 50%, 30% 20%, 45% 20%);
}

[data-shape="cell"] {
  clip-path: polygon(
    50% 0%,
    60% 10%,
    70% 0%,
    80% 10%,
    90% 0%,
    100% 10%,
    90% 20%,
    100% 30%,
    90% 40%,
    100% 50%,
    90% 60%,
    100% 70%,
    90% 80%,
    100% 90%,
    90% 100%,
    80% 90%,
    70% 100%,
    60% 90%,
    50% 100%,
    40% 90%,
    30% 100%,
    20% 90%,
    10% 100%,
    0% 90%,
    10% 80%,
    0% 70%,
    10% 60%,
    0% 50%,
    10% 40%,
    0% 30%,
    10% 20%,
    0% 10%,
    10% 0%,
    20% 10%,
    30% 0%,
    40% 10%
  );
}

[data-shape="petri-dish"] {
  clip-path: polygon(50% 0%, 80% 20%, 100% 50%, 80% 80%, 50% 100%, 20% 80%, 0% 50%, 20% 20%);
}

[data-shape="microscopic-slide"] {
  clip-path: polygon(
    10% 0%,
    90% 0%,
    100% 10%,
    100% 90%,
    90% 100%,
    10% 100%,
    0% 90%,
    0% 10%,
    50% 50%
  );
}

[data-shape="microscope"] {
  clip-path: polygon(
    0% 0%,
    30% 0%,
    30% 40%,
    70% 40%,
    70% 0%,
    100% 0%,
    100% 70%,
    70% 70%,
    70% 100%,
    30% 100%,
    30% 70%,
    0% 70%
  );
}

[data-shape="antigen"] {
  clip-path: polygon(
    50% 0%,
    65% 10%,
    80% 0%,
    95% 10%,
    100% 30%,
    80% 50%,
    100% 70%,
    95% 90%,
    80% 100%,
    65% 90%,
    50% 100%,
    35% 90%,
    20% 100%,
    5% 90%,
    0% 70%,
    20% 50%,
    0% 30%,
    5% 10%,
    20% 0%,
    35% 10%
  );
}

[data-shape="chromosome"] {
  clip-path: polygon(
    20% 0%,
    30% 20%,
    40% 10%,
    50% 20%,
    60% 10%,
    70% 20%,
    80% 0%,
    90% 10%,
    80% 30%,
    70% 50%,
    80% 70%,
    90% 90%,
    80% 100%,
    70% 80%,
    60% 90%,
    50% 80%,
    40% 90%,
    30% 80%,
    20% 100%,
    10% 90%,
    20% 70%,
    30% 50%,
    20% 30%,
    10% 10%
  );
}

[data-shape="microscope-slide-detailed-rectangle"] {
  clip-path: polygon(
    10% 0%,
    90% 0%,
    90% 10%,
    80% 10%,
    80% 20%,
    90% 20%,
    90% 80%,
    80% 80%,
    80% 90%,
    90% 90%,
    90% 100%,
    10% 100%,
    10% 90%,
    20% 90%,
    20% 80%,
    10% 80%,
    10% 20%,
    20% 20%,
    20% 10%,
    10% 10%
  );
}

[data-shape="gene-segment"] {
  clip-path: polygon(
    10% 0%,
    90% 0%,
    90% 20%,
    80% 20%,
    80% 40%,
    90% 40%,
    90% 60%,
    80% 60%,
    80% 80%,
    90% 80%,
    90% 100%,
    10% 100%,
    10% 80%,
    20% 80%,
    20% 60%,
    10% 60%,
    10% 40%,
    20% 40%,
    20% 20%,
    10% 20%
  );
}

[data-shape="petri-dish-circle-with-sectors"] {
  clip-path: polygon(
    50% 0%,
    65% 15%,
    80% 0%,
    95% 15%,
    100% 50%,
    85% 65%,
    100% 80%,
    85% 95%,
    50% 100%,
    35% 85%,
    20% 100%,
    5% 85%,
    0% 50%,
    15% 35%,
    0% 20%,
    15% 5%,
    50% 0%
  );
}

[data-shape="virus-complex-spiky-circle"] {
  clip-path: polygon(
    50% 0%,
    55% 10%,
    65% 5%,
    70% 15%,
    80% 10%,
    85% 20%,
    95% 15%,
    100% 25%,
    90% 35%,
    95% 45%,
    85% 50%,
    90% 60%,
    80% 65%,
    85% 75%,
    70% 80%,
    75% 90%,
    60% 85%,
    65% 95%,
    50% 100%,
    45% 90%,
    35% 95%,
    30% 85%,
    20% 90%,
    15% 80%,
    5% 75%,
    10% 65%,
    0% 60%,
    10% 50%,
    0% 45%,
    10% 35%,
    0% 25%,
    15% 20%,
    5% 15%,
    20% 10%,
    15% 5%,
    30% 10%,
    25% 0%
  );
}

[data-shape="neural-network"] {
  clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
}

[data-shape="chip"] {
  clip-path: polygon(25% 0%, 75% 0%, 100% 25%, 100% 75%, 75% 100%, 25% 100%, 0% 75%, 0% 25%);
}

[data-shape="ai-circuit"] {
  clip-path: polygon(
    25% 0%,
    75% 0%,
    75% 25%,
    100% 25%,
    100% 75%,
    75% 75%,
    75% 100%,
    25% 100%,
    25% 75%,
    0% 75%,
    0% 25%,
    25% 25%
  );
}

[data-shape="brain"] {
  clip-path: polygon(
    50% 0%,
    70% 10%,
    80% 20%,
    90% 40%,
    80% 60%,
    70% 80%,
    50% 100%,
    30% 80%,
    20% 60%,
    10% 40%,
    20% 20%,
    30% 10%
  );
}

[data-shape="graph-network"] {
  clip-path: polygon(
    10% 10%,
    30% 10%,
    30% 30%,
    50% 30%,
    50% 10%,
    70% 10%,
    70% 50%,
    90% 50%,
    90% 70%,
    70% 70%,
    70% 90%,
    30% 90%,
    30% 70%,
    10% 70%,
    10% 30%,
    30% 30%
  );
}

@charset 'UTF-8';

.viewport-surface {
  --position-xy: 0;

  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100vh;
  left: var(--position-xy);
  overflow: hidden;
  place-content: center center;
  pointer-events: none;
  position: fixed;
  top: var(--position-xy);
  width: 100vw;
  z-index: -2;
}

/* ----------------------------------------------- */
.antibodies,
.binary-code,
.word-grid {
  --size: 100%;
  --position-x: calc(50% - (var(--size) / 2));
  --position-y: calc(50% - (var(--size) / 2));

  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  height: var(--size);
  justify-content: center;
  left: var(--position-x);
  position: absolute;
  top: var(--position-y);
  width: var(--size);
}

/* ----------------------------------------------- */
.antibody-group-list {
  --size: 90vw;
  --radius-x: calc(var(--size) / 2.5); /* Horizontal radius */
  --radius-y: calc(var(--size) / 4); /* Vertical radius */

  display: grid;
  height: var(--size);
  list-style-type: none;
  margin: 0;
  opacity: 0;
  padding: 0;
  place-items: center;
  position: relative;
  transition-duration: var(--speed-normal);
  transition-property: opacity;
  transition-timing-function: var(--easing-color-or-opacity);
  width: var(--size);
}

[data-is-loaded="true"] .antibody-group-list {
  opacity: 1;
}

@media (width <= 1024px) {
  .antibody-group-list {
    --size: 110vw;
  }
}

@media (width <= 768px) {
  .antibody-group-list {
    --size: 140vw;
  }
}

@media (width <= 540px) {
  .antibody-group-list {
    --size: 80vh;
  }

  .word-grid {
    display: none;
  }
}

.antibody-group-list li {
  position: absolute;
}

.antibody-group-list li svg {
  --size: 52px;

  height: var(--size);
  width: var(--size);
}

.antibody-group-list li[data-icon="top-left"],
.antibody-group-list li[data-icon="top-right"],
.antibody-group-list li[data-icon="bottom-left"],
.antibody-group-list li[data-icon="bottom-right"] {
  --anchor-position: 0;
  --size: 152px;

  left: var(--anchor-position);
  position: fixed;
  top: var(--anchor-position);
}

.antibody-group-list li[data-icon="top-left"],
.antibody-group-list li[data-icon="top-right"] {
  top: var(--anchor-position);
}

.antibody-group-list li[data-icon="bottom-left"],
.antibody-group-list li[data-icon="bottom-right"] {
  bottom: var(--anchor-position);
  top: auto;
}

.antibody-group-list li[data-icon="top-right"],
.antibody-group-list li[data-icon="bottom-right"] {
  left: auto;
  right: var(--anchor-position);
}

.antibody-group-list li[data-icon="top-left"],
.antibody-group-list li[data-icon="bottom-left"] {
  left: var(--anchor-position);
}

.antibody-group-list li[data-icon="top-left"] {
  transform: translate(-50%, -50%);
}

.antibody-group-list li[data-icon="top-right"] {
  transform: translate(50%, -50%);
}

.antibody-group-list li[data-icon="bottom-left"] {
  transform: translate(-50%, 50%);
}

.antibody-group-list li[data-icon="bottom-right"] {
  transform: translate(50%, 50%);
}

/* ----------------------------------------------- */

/* Calculate angle increment based on 10 items (360 degrees / 10 items = 36 degrees per item) */
.antibody-group-list li[data-icon="1"] {
  transform: rotate(0deg) translate(calc(var(--size) / 2.5)) rotate(0deg);
}

[data-route="/meeting/"] .antibody-group-list li[data-icon="1"],
[data-route="/publishing/"] .antibody-group-list li[data-icon="1"],
[data-route="/about/"] .antibody-group-list li[data-icon="1"] {
  display: none;
}

.antibody-group-list li[data-icon="2"] {
  transform: rotate(36deg) translate(calc(var(--size) / 2.5)) rotate(-36deg);
}

[data-route="/"] .antibody-group-list li[data-icon="2"] {
  display: none;
}

.antibody-group-list li[data-icon="3"] {
  transform: rotate(72deg) translate(calc(var(--size) / 2.5)) rotate(-72deg);
}

.antibody-group-list li[data-icon="4"] {
  transform: rotate(108deg) translate(calc(var(--size) / 2.5)) rotate(-108deg);
}

.antibody-group-list li[data-icon="5"] {
  transform: rotate(144deg) translate(calc(var(--size) / 2.5)) rotate(-144deg);
}

[data-route="/"] .antibody-group-list li[data-icon="5"] {
  display: none;
}

.antibody-group-list li[data-icon="6"] {
  transform: rotate(180deg) translate(calc(var(--size) / 2.5)) rotate(-180deg);
}

.antibody-group-list li[data-icon="7"] {
  transform: rotate(216deg) translate(calc(var(--size) / 2.5)) rotate(-216deg);
}

.antibody-group-list li[data-icon="8"] {
  transform: rotate(252deg) translate(calc(var(--size) / 2.5)) rotate(-252deg);
}

.antibody-group-list li[data-icon="9"] {
  transform: rotate(288deg) translate(calc(var(--size) / 2.5)) rotate(-288deg);
}

.antibody-group-list li[data-icon="10"] {
  transform: rotate(324deg) translate(calc(var(--size) / 2.5)) rotate(-324deg);
}

/* ----------------------------------------------- */
.binary-code,
.binary-code li {
  --font-size: 12px;
}

.binary-code {
  --position-y: 0;

  align-items: flex-start;
  color: var(--color-midnight-blue-500);
  -moz-column-gap: calc(var(--font-size) / 4);
       column-gap: calc(var(--font-size) / 4);
  font-family: var(--font-family-code-light);
  font-size: var(--font-size);
  line-height: var(--font-size);
  list-style: none;
  margin: 0;
  padding: 0;
  transform: translateY(-90%);
}

[data-is-loaded="true"] .binary-code {
  transform: translateY(0);
  transition-duration: var(--speed-slow);
  transition-property: transform;
  transition-timing-function: var(--easing-entering);
}

.binary-code li {
  align-self: auto;
  flex-basis: var(--font-size);
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

.binary-code li::before {
  --size: 100%;
  --position: 0;

  background: linear-gradient(to bottom, rgb(0 0 0 / 0%) 0%, var(--color-midnight-blue-900) 100%);
  content: "";
  height: var(--size);
  left: var(--position);
  pointer-events: none;
  position: absolute;
  top: var(--position);
  width: var(--size);
}

.binary-code em {
  display: block;
  font-style: normal;
}

.binary-code em.bright {
  color: var(--color-midnight-blue-400);
}

.binary-code em.electric {
  color: var(--color-electric-blue-400);
}

/* ----------------------------------------------- */
.word-grid-list {
  --size: 100%;

  color: var(--color-midnight-blue-400);
  display: grid;
  font-family: var(--font-family-code-light);
  font-size: var(--font-size-body-big-fluid);
  grid-gap: 0;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  height: var(--size);
  list-style: none;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  width: var(--size);
}

.word-grid-list li {
  display: grid;
  place-content: center;
}

.grid-cell-1 {
  grid-area: 2 / 1 / 3 / 2;
}

.grid-cell-2 {
  grid-area: 2 / 5 / 3 / 6;
}

.grid-cell-3 {
  grid-area: 4 / 1 / 5 / 2;
}

.grid-cell-4 {
  grid-area: 4 / 5 / 5 / 6;
}

.grid-cell-5 {
  grid-area: 5 / 2 / 6 / 3;
}

.grid-cell-6 {
  grid-area: 5 / 4 / 6 / 5;
}

.grid-cell-7 {
  grid-area: 1 / 2 / 2 / 3;
}

.grid-cell-8 {
  grid-area: 1 / 4 / 2 / 5;
}

/* ----------------------------------------------- */
.lightbox {
  --position-xy: 0;
  --surface-opacity: 0%;

  align-items: center;
  background-color: rgb(0 0 0 / var(--surface-opacity));
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: var(--position-xy);
  overflow: hidden;
  padding: var(--gutter);
  place-content: center center;
  pointer-events: auto;
  position: fixed;
  top: var(--position-xy);
  transition-property: background-color;
  width: 100vw;
  z-index: 1;
}

.lightbox-image-frame {
  background-color: var(--color-white);
  box-shadow: var(--shadow-elevation-high);
  display: inline-flex;
  padding: var(--gutter);
}

.lightbox img {
  --min-width: 196px;
  --max-width: 1242px;
  --preferred-width: 90vw;

  display: block;
  opacity: 0;
  transform: scale(0.25);
  transform-origin: center center;
  transition-property: opacity, transform;
  width: clamp(var(--min-width), var(--preferred-width), var(--max-width));
}

.lightbox[data-image-is-loaded="true"] {
  --surface-opacity: 75%;

  transition-duration: var(--speed-fast);
  transition-timing-function: var(--easing-color-or-opacity);
}

.lightbox[data-image-is-loaded="true"] img {
  opacity: 1;
  transform: scale(1);
  transition-duration: var(--speed-fast), var(--speed-extra-fast);
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-entering);
}

/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-max-compound-selectors */
@charset 'UTF-8';

ul.carousel,
ul.carousel li,
.top-app-bar ul,
.top-app-bar ul li {
  --column-gap: calc(var(--gutter) * 3);
}

@media (width <= 1024px) {
  ul.carousel,
  ul.carousel li,
  .top-app-bar ul,
  .top-app-bar ul li {
    --column-gap: calc(var(--gutter) * 2);
  }
}

ul.carousel,
.top-app-bar ul {
  --horizontal-padding: 5vw;

  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: var(--gutter) var(--column-gap);
  list-style: none;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  max-width: 1280px;
  padding-bottom: 0;
  padding-left: var(--horizontal-padding);
  padding-right: var(--horizontal-padding);
  padding-top: 0;
  place-content: center space-evenly;
  text-align: center;
}

ul.carousel li,
.top-app-bar ul li {
  align-self: auto;
  flex-basis: calc(100% / 3 - var(--column-gap));
  flex-grow: 1;
  flex-shrink: 1;
}

.top-app-bar ul {
  flex-wrap: wrap;
}

.top-app-bar li.brandmark-row {
  flex-basis: 100%;
  position: relative;
}

/* ----------------------------------------------- */
.carousel-item-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  place-content: flex-start flex-start;
  position: relative;
  row-gap: calc(var(--gutter) / 2);
}

.carousel-item-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

.carousel-item-media {
  --min-width: 152px;
  --max-width: 256px;
  --preferred-width: 100%;

  align-content: center;
  aspect-ratio: 1 / 1;
  background-color: var(--color-black);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  transform-origin: center center;
  width: clamp(var(--min-width), var(--preferred-width), var(--max-width));
}

/* ----------------------------------------------- */
.carousel-item-header,
.carousel-item-call-to-action {
  text-shadow: var(--text-shadow-black);
}

.carousel-item-header {
  padding-top: calc(var(--gutter) / 2);
}

.carousel h2 {
  --vertical-margin: 0;

  font-family: var(--font-family-code-bold);
  font-size: var(--font-size-h6-fluid);
}

.carousel-item-call-to-action {
  font-family: var(--font-family-code-regular);
  font-size: var(--font-size-body-small);
  line-height: var(--type-scale-golden-ratio);
}

/* ----------------------------------------------- */

.carousel-item-header a {
  color: var(--color-white);
  transition-duration: var(--speed-fast);
  transition-property: color;
  transition-timing-function: var(--easing-color-or-opacity);
}

a.carousel-item-media:hover::before,
.carousel-item-header a:hover {
  --highlight-color: var(--color-electric-blue-400);
}

.carousel-item-header a:hover,
.carousel-item-header a[data-is-active="true"] {
  color: var(--highlight-color);
  transition-duration: var(--speed-super-fast);
}

.carousel-item-header a:active,
.carousel-item-header a:focus {
  color: var(--color-electric-blue-100);
  transition-property: none;
}

/* ----------------------------------------------- */
a.carousel-item-media::before {
  --size: 100%;
  --position: 0;

  background-color: var(--color-midnight-blue-800);
  content: "";
  display: grid;
  height: var(--size);
  left: var(--position);
  place-content: center;
  position: absolute;
  top: var(--position);
  transform-origin: center center;
  transition-duration: var(--speed-normal);
  transition-property: background-color;
  transition-timing-function: var(--easing-color-or-opacity);
  width: var(--size);
  z-index: -1;
}

a.carousel-item-media:hover::before {
  --highlight-color: var(--color-electric-blue-400);

  background-color: var(--highlight-color);
  background-image: none;
  transition-duration: var(--speed-fast);
  transition-timing-function: var(--easing-entering);
}

ul.carousel li:nth-child(1) .carousel-item-media:hover::before,
ul.carousel li:nth-child(1) .carousel-item-header a:hover,
ul.carousel li:nth-child(1) .carousel-item-header a[data-is-active="true"] {
  --highlight-color: var(--color-lemon-yellow-500);
}

ul.carousel li:nth-child(3) .carousel-item-media:hover::before,
ul.carousel li:nth-child(3) .carousel-item-header a:hover,
ul.carousel li:nth-child(3) .carousel-item-header a[data-is-active="true"] {
  --highlight-color: var(--color-ruby-red-500);
}

a.carousel-item-media img,
a.carousel-item-media video {
  --media-size: calc(100% - var(--gutter) * 0.5);
  --media-position: calc(50% - var(--media-size) / 2);

  display: inline-flex;
  height: var(--media-size);
  left: var(--media-position);
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size);
}

/* ----------------------------------------------- */
@media (width <= 768px) {
  ul.carousel {
    --horizontal-padding: 0;

    flex-direction: column;
  }

  .carousel-item-container {
    display: block;
    text-align: left;
  }

  .carousel-item-cell {
    --media-width: 120px;

    display: block;
  }

  .carousel-item-media {
    /* stylelint-disable-next-line property-disallowed-list */
    float: left;
    margin-left: 0;
    margin-right: 0;
    width: var(--media-width);
  }

  .carousel-item-header,
  .carousel-item-call-to-action {
    margin-left: calc(var(--media-width) + var(--gutter));
  }

  .carousel-item-header {
    padding-bottom: calc(var(--gutter) / 2);
    padding-top: 0;
  }

  .carousel h2 {
    font-size: var(--font-size-h6-fluid);
  }

  .carousel-item-call-to-action {
    font-size: var(--font-size-body-fluid);
  }

  ul.carousel li {
    border-bottom: 3px solid var(--color-midnight-blue-500);
    padding-bottom: var(--gutter);
  }

  ul.carousel li:last-child {
    border-bottom: none;
  }
}

@media (width <= 540px) {
  .carousel-item-container {
    text-align: center;
  }

  .carousel-item-cell {
    --media-width: 180px;
  }

  .carousel-item-media {
    /* stylelint-disable-next-line property-disallowed-list */
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  .carousel-item-header,
  .carousel-item-call-to-action {
    margin-left: 0;
  }

  .carousel-item-header {
    padding-top: var(--gutter);
  }

  ul.carousel li {
    padding-left: var(--gutter);
    padding-right: var(--gutter);
  }
}

@charset 'UTF-8';

.input-helper {
  display: block;
  font-size: var(--font-size-body-small);
  line-height: var(--type-scale-perfect-fourth);
  padding-top: calc(var(--gutter) / 4);
}

/* ----------------------------------------------- */
button[type="submit"] {
  --font-size: var(--font-size-h6-fluid);
  --gap: calc(var(--gutter) / 2);
  --surface-color: var(--color-ruby-red-700);
  --on-surface-color: var(--color-white);

  align-items: center;
  background-color: var(--surface-color);
  background-image: linear-gradient(
    0deg,
    hsl(336deg 100% 25%) 0%,
    hsl(337deg 100% 28%) 29%,
    hsl(339deg 98% 32%) 43%,
    hsl(342deg 93% 36%) 57%,
    hsl(345deg 87% 41%) 71%,
    hsl(349deg 81% 46%) 100%
  );
  background-repeat: no-repeat;
  border-color: var(--color-ruby-red-600);
  border-radius: var(--rounded-corners-medium);
  border-style: solid;
  border-width: 2px;
  box-shadow: var(--shadow-elevation-medium);
  color: var(--on-surface-color);
  -moz-column-gap: var(--gap);
       column-gap: var(--gap);
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-code-semi-bold);
  font-size: var(--font-size);
  line-height: var(--font-size);
  margin-left: auto;
  margin-right: auto;
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
  overflow: hidden;
  padding: var(--gap);
  place-content: center center;
  position: relative;
  text-align: center;
  text-shadow: var(--text-shadow-button-primary);
  transition-duration: var(--speed-fast), var(--speed-normal);
  transition-property: background-color, box-shadow;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity);
  white-space: nowrap;
}

button[data-is-disabled="true"] {
  pointer-events: none;
}

button[type="submit"]:hover,
button[type="submit"]:active,
button[type="submit"]:focus {
  text-shadow: none;
}

button[type="submit"]:hover {
  --surface-color: var(--color-ruby-red-500);

  background-image: none;
  border-color: var(--color-ruby-red-700);
  box-shadow: var(--shadow-elevation-high);
  transition-duration: var(--speed-extra-fast), var(--speed-fast);
}

button[type="submit"]:active,
button[type="submit"]:focus {
  --surface-color: var(--color-ruby-red-700);

  box-shadow: var(--shadow-elevation-low);
  transition-property: none;
}

/* ----------------------------------------------- */
.newsletter-subscribe-form button[type="submit"] {
  /* Optically offset */
  margin-top: -1px;
}

/* ----------------------------------------------- */
button svg {
  fill: currentcolor;
}

/* ----------------------------------------------- */
label {
  display: block;
  position: relative;
}

label[data-is-error="true"]::after {
  --font-size: var(--font-size-body-small);

  bottom: calc(var(--font-size) * -1.5);
  color: var(--color-yellow-900);
  content: attr(data-error-message);
  font-family: var(--font-family-code-medium);
  font-size: var(--font-size);
  left: 0;
  line-height: var(--font-size);
  position: absolute;
}

/* ----------------------------------------------- */
label span {
  cursor: pointer;
  display: inline-block;
}

fieldset label span {
  text-shadow: var(--text-shadow-white);
}

.checkbox-label,
.checkbox-label span,
.radio-label,
.radio-label span,
input[type="checkbox"],
input[type="radio"] {
  --input-size: 18px;
}

/* ----------------------------------------------- */
fieldset {
  --freespace: calc(var(--gutter) / 2);

  background-color: var(--color-dark-gray-050);
  border-color: var(--color-cool-gray-600);
  border-radius: var(--rounded-corners-medium);
  border-style: solid;
  border-width: 2px;
  box-shadow: var(--shadow-elevation-low);
  margin-bottom: var(--freespace);
  margin-top: calc(var(--freespace) * 2);
  padding-bottom: var(--freespace);
  padding-left: var(--freespace);
  padding-right: var(--freespace);
  padding-top: calc(var(--freespace) / 4);
}

/* ----------------------------------------------- */
input[type="text"]::-moz-placeholder, input[type="email"]::-moz-placeholder, input[type="number"]::-moz-placeholder, select::-moz-placeholder {
  color: var(--color-neutral-800);
  opacity: 1; /* Firefox */
}
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="number"]::placeholder,
select::placeholder {
  color: var(--color-neutral-800);
  opacity: 1; /* Firefox */
}

input[type="text"],
input[type="email"],
input[type="number"],
select,
textarea {
  --font-size: var(--font-size-body);
  --surface-color: var(--color-white);
  --decoration-color: var(--color-white);
  --decoration-width: 4px;
  --on-surface-color: var(--color-black);
  --horizontal-padding: calc(var(--font-size) / 2);

  background-color: var(--surface-color);
  border-color: var(--decoration-color);

  /* border-radius: var(--rounded-corners-small); */
  border-style: solid;
  border-width: var(--decoration-width);
  color: var(--color-black);
  display: block;
  font-family: var(--font-family-regular);
  font-size: var(--font-size);
  line-height: var(--font-size);
  min-height: calc(var(--font-size) * 2.5 + var(--decoration-width) * 1);
  outline: none;
  padding-bottom: 0;
  padding-left: var(--horizontal-padding);
  padding-right: var(--horizontal-padding);
  padding-top: 1px;
  width: 100%;
}

input[autoComplete="postal-code"] {
  max-width: 20ch;
}

/* ----------------------------------------------- */
textarea {
  min-height: 80px;
}

/* ----------------------------------------------- */
.button-group {
  --gap: var(--gutter);

  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: calc(var(--gap) / 2) var(--gap);
  list-style: none;
  margin: 0;
  padding: 0;
  place-content: center flex-start;
}

.button-group li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

/* ----------------------------------------------- */
.newsletter-subscribe-form input[type="text"],
.newsletter-subscribe-form input[type="email"] {
  font-family: var(--font-family-code-medium);
  min-width: 200px;
  transition-duration: var(--speed-normal);
  transition-property: box-shadow;
  transition-timing-function: var(--easing-exiting);
}

.newsletter-subscribe-form input[type="text"]:focus,
.newsletter-subscribe-form input[type="email"]:focus {
  --decoration-color: var(--color-electric-blue-500);

  box-shadow: var(--shadow-elevation-medium);
  transition-duration: var(--speed-fast);
  transition-timing-function: var(--easing-entering);
}

/* ----------------------------------------------- */
.error-message {
  display: block;
  font-family: var(--font-family-bold);
  font-size: var(--font-size-body-small);
  margin-top: calc(var(--gap) / 2);
  pointer-events: none;
}

/* ----------------------------------------------- */
.newsletter-subscribe-form {
  position: relative;
}

.newsletter-subscribe-form legend {
  color: var(--color-white);
  line-height: var(--type-scale-golden-ratio);

  /* margin-top: 0; */
  max-width: 60ch;
  text-shadow: var(--text-shadow-black);
}

/* ----------------------------------------------- */
.newsletter-subscribe-form-elements {
  --gap: calc(var(--gutter) / 2);

  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 var(--gap);
  margin-top: calc(var(--gap) * 2);
  place-content: center space-between;
}

.newsletter-subscribe-form-elements-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.newsletter-subscribe-form-elements-cell-submit {
  flex-grow: 0;
  flex-shrink: 0;
}

/* ----------------------------------------------- */
@media (width <= 375px) {
  .newsletter-subscribe-form-elements-cell {
    flex-basis: 100%;
  }
}

/* ----------------------------------------------- */
.button-group.is-form-submit {
  margin-top: var(--gutter);
}

@charset 'UTF-8';

.subscribe-sheet,
.subscribe-sheet::after {
  --sheet-height: auto;
  --sheet-width: 100%;
}

.subscribe-sheet {
  --position-xy: 0;

  background-color: var(--color-neutral-900);
  height: var(--sheet-height);
  left: var(--position-xy);
  padding-bottom: calc(var(--gutter) / 2);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  padding-top: calc(var(--gutter) / 2);
  pointer-events: none;
  position: fixed;
  top: var(--position-xy);
  transform: translateY(-100%);
  transition-duration: var(--speed-fast);
  transition-property: transform;
  transition-timing-function: var(--easing-exiting);
  width: var(--sheet-width);
  z-index: 1000;
}

.subscribe-sheet::after {
  --curtain-height: calc(var(--gutter) * 0.5);

  background: var(--window-shade);
  background-repeat: no-repeat;
  background-size: var(--sheet-width) var(--curtain-height);
  bottom: calc(var(--curtain-height) * -1);
  content: "";
  height: var(--curtain-height);
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: auto;
  transition-duration: var(--speed-fast);
  transition-property: opacity;
  transition-timing-function: var(--easing-color-or-opacity);
  width: var(--sheet-width);
}

/* ----------------------------------------------- */
.subscribe-sheet[data-is-visible="true"] {
  pointer-events: auto;
  transform: translateY(0);
  transition-duration: var(--speed-fast);
  transition-timing-function: var(--easing-entering);
}

.subscribe-sheet[data-is-visible="true"]::after {
  opacity: 1;
}

/* ----------------------------------------------- */
.subscribe-success-message {
  max-width: 90ch;
}

.subscribe-sheet .subscribe-success-message,
.subscribe-sheet[data-is-show-subscribe-success="true"] .newsletter-subscribe-form {
  display: none;
}

.subscribe-sheet[data-is-show-subscribe-success="true"] .subscribe-success-message {
  display: block;
}

/* stylelint-disable no-descending-specificity */
/* stylelint-disable declaration-no-important */
@charset 'UTF-8';

.article-date-publisher-and-doi-container,
.article-date-publisher-and-doi-container-cell,
.publisher-logo-container {
  --logo-basis: 160px;
}

/* ----------------------------------------------- */
.scholarly-article:not(:first-of-type) {
  border-top: 1px solid var(--color-electric-blue-700);
  margin-top: calc(var(--gutter) * 1.1);
}

.scholarly-article a,
.scholarly-article time,
.scholarly-article dl[itemtype="http://schema.org/Periodical"] dd[itemprop="name"]
{
  color: var(--color-electric-blue-300);
}

.scholarly-article [itemprop="headline"] {
  color: var(--color-white);
  font-family: var(--font-family-code-medium);
  font-size: var(--font-size-h6-fluid);
  line-height: var(--type-scale-golden-ratio);
}

.scholarly-article ul.authors,
.scholarly-article dl[itemtype="http://schema.org/Periodical"],
.scholarly-article dl.doi {
  --gap: calc(var(--gutter) / 8);

  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap) var(--gap);
  list-style-type: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: var(--gap);
  padding: 0;
  place-content: flex-start flex-start;
  text-shadow: var(--text-shadow-black);
}

.scholarly-article dl[itemtype="http://schema.org/Periodical"].article-date-and-publisher
{
  flex-direction: column;
  margin-top: calc(var(--gap) * 1.75 * -1);
}

.scholarly-article ul.authors {
  row-gap: calc(var(--gap) / 2);
}

.scholarly-article ul.authors li,
.scholarly-article dl[itemtype="http://schema.org/Periodical"] dt,
.scholarly-article dl[itemtype="http://schema.org/Periodical"] dd,
.scholarly-article dl.doi {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.scholarly-article ul.authors li:not(:last-child)::after {
  content: ",";
}

/* ----------------------------------------------- */
.scholarly-article dl[itemtype="http://schema.org/Periodical"],
.scholarly-article dl[itemtype="http://schema.org/Periodical"] dd
{
  position: relative;
}

.scholarly-article dl[itemtype="http://schema.org/Periodical"] dt
{
  display: none;
}

.scholarly-article dl[itemtype="http://schema.org/Periodical"] dd[itemprop="name"]
{
  flex-shrink: 1;
  font-family: var(--font-family-code-medium-italic);
}

/* ----------------------------------------------- */

.scholarly-article dl[itemtype="http://schema.org/Periodical"] dd.publisher-logo
{
  flex-basis: 100%;
  padding-bottom: calc(var(--gutter) / 2);
  padding-top: calc(var(--gutter) / 2);
}

.publisher-logo-container {
  --horizontal-freespace: calc(var(--gutter) / 2);

  align-items: center;
  aspect-ratio: 2 / 1;
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: var(--logo-basis);
  padding-left: var(--horizontal-freespace);
  padding-right: var(--horizontal-freespace);
  place-content: center center;
}

/* ----------------------------------------------- */
.scholarly-article [itemprop="headline"] a {
  --surface-color: var(--color-black);
  --on-surface-color: var(--color-electric-blue-400);

  background-color: var(--surface-color);
  border-bottom: 2px solid var(--surface-color);
  color: var(--on-surface-color);
  display: inline;
  position: relative;
  transition-duration: var(--speed-fast);
  transition-property: color;
  transition-timing-function: var(--easing-color-or-opacity);
}

.scholarly-article [itemprop="headline"] a:hover {
  --on-surface-color: var(--color-white);

  transition-duration: var(--speed-extra-fast);
}

.scholarly-article [itemprop="headline"] a:active,
.scholarly-article [itemprop="headline"] a:focus {
  --on-surface-color: var(--color-lemon-yellow-500);

  transition-property: none;
}

.article-date-publisher-and-doi-container {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: calc(var(--gutter) / 2) var(--gutter);
  margin-top: var(--gutter);
  place-content: center flex-start;
}

.article-date-publisher-and-doi-container-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  order: 1;
}

.article-date-publisher-and-doi-container-cell-publisher-logo {
  flex-basis: var(--logo-basis);
  flex-grow: 0;
  order: 0;
}

/* ----------------------------------------------- */
@media (width <= 1024px) {
  .article-date-publisher-and-doi-container {
    flex-direction: column;
    margin-top: calc(var(--gutter) / 2);
  }

  .article-date-publisher-and-doi-container-cell {
    order: 0;
  }

  .article-date-publisher-and-doi-container-cell-publisher-logo {
    flex-basis: auto;
    order: 1;
  }
}

/* ----------------------------------------------- */
.scholarly-article-image-gallery,
.scholarly-article-image-gallery li {
  --gap: calc(var(--gutter) / 2);
  --images-per-row: 3;
}

.scholarly-article-image-gallery {
  align-items: center;
  background-color: var(--color-black);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap) var(--gap);
  list-style: none;
  margin-top: calc(var(--gutter) * 1.5);
  padding: var(--gap);
  place-content: stretch flex-start;
}

.scholarly-article-image-gallery li {
  --gallery-image-width: calc(100% / var(--images-per-row) - var(--gap) * 0.68);

  align-self: auto;
  aspect-ratio: 2 / 1;
  flex-basis: var(--gallery-image-width);
  flex-grow: 0;
  flex-shrink: 1;
  position: relative;
  width: var(--gallery-image-width);
}

.scholarly-article-image-gallery img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  display: inline-flex;
  height: var(--media-size) !important;
  left: var(--media-position);
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size) !important;
}

/* ----------------------------------------------- */
.scholarly-article-image-gallery a {
  --size: 100%;

  background-color: var(--color-white);
  border-color: var(--color-white);
  border-style: solid;
  border-width: 1px;
  display: block;
  height: var(--size);
  position: relative;
  width: var(--size);
}

.scholarly-article-image-gallery a:hover,
.scholarly-article-image-gallery a:active,
.scholarly-article-image-gallery a:focus {
  border-color: var(--color-electric-blue-300);
}

@media (width <= 414px) {
  .scholarly-article-image-gallery {
    display: none;
  }
}

@charset 'UTF-8';

.alert,
.alert div,
.alert-icon {
  --icon-size: 20px;
}

.alert,
.alert div {
  --gap: calc(var(--gutter) / 4);
}

.alert {
  --surface-color: var(--color-white);
  --on-surface-color: var(--color-black);
  --font-size: var(--font-size-body-small);
  --position-x: var(--gutter);
  --position-y: var(--gutter);
  --min-width: 200px;
  --max-width: 414px;
  --preferred-width: 100%;

  align-items: center;
  background-color: var(--surface-color);
  border-radius: var(--rounded-corners-small);
  box-shadow: var(--shadow-elevation-medium);
  color: var(--on-surface-color);
  -moz-column-gap: var(--gap);
       column-gap: var(--gap);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-bold);
  font-size: var(--font-size);
  line-height: var(--font-size);
  place-content: flex-start flex-start;
  pointer-events: none;
  position: fixed;
  right: var(--position-x);
  top: var(--position-y);
  transform: translateX(calc(100% + var(--gutter)));
  visibility: hidden;
  width: clamp(var(--min-width), var(--preferred-width), var(--max-width));
  z-index: 100;
}

.alert[data-alert-is-visible="true"] {
  pointer-events: all;
  transform: translateX(0);
  transition-duration: var(--speed-extra-fast);
  transition-property: transform;
  transition-timing-function: var(--easing-entering);
  visibility: visible;
}

.alert div {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;

  /* order: 0; */
  position: relative;
}

.alert div.icon,
.alert div.action {
  --padding: calc(var(--icon-size) / 2);

  flex-basis: var(--icon-size);
  flex-grow: 0;
  padding: var(--padding);
}

.alert div.action {
  --padding: calc(var(--icon-size) / 4);

  align-self: flex-start;
}

.alert div.message {
  padding-bottom: var(--gap);
  padding-top: var(--gap);
}

/* ----------------------------------------------- */
.alert-icon {
  --surface-color: var(--color-midnight-blue-900);
  --on-surface-color: var(--color-white);

  align-items: center;
  background-color: var(--surface-color);
  border-radius: 100%;
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: var(--icon-size);
  place-content: center center;
  width: var(--icon-size);
}

.alert-icon svg {
  fill: currentcolor;
  stroke-width: 0;
}

/* ----------------------------------------------- */
.alert-dismiss {
  /* stylelint-disable-next-line declaration-no-important */
  --surface-color: transparent !important;
  --on-surface-color: var(--color-midnight-blue-900);
}

/* ----------------------------------------------- */
.alert.success {
  --surface-color: var(--color-green-050);
  --on-surface-color: var(--color-green-900);

  text-shadow: var(--text-shadow-white);
}

.alert.success .alert-icon {
  --surface-color: var(--color-green-900);
}

.alert.success .alert-dismiss {
  --on-surface-color: var(--color-green-900);
}

/* stylelint-disable declaration-no-important */
@charset 'UTF-8';

button.lightbox-trigger {
  --outline-color: var(--color-electric-blue-700);
  --outline-width: 1px;
  --size: 100%;

  display: block;
  height: var(--size);
  outline-color: var(--outline-color) !important;
  outline-offset: calc(var(--outline-width) * 4) !important;
  outline-style: solid !important;
  outline-width: var(--outline-width) !important;
  transition-duration: var(--speed-fast);
  transition-property: outline-color;
  transition-timing-function: var(--easing-color-or-opacity);
  width: var(--size);
}

button.lightbox-trigger:hover {
  --outline-color: var(--color-electric-blue-300);
}

button.lightbox-trigger:active,
button.lightbox-trigger:focus {
  --outline-color: var(--color-electric-blue-100);

  transition-property: none;
}

/* ----------------------------------------------- */
.button-with-icon,
/* stylelint-disable-next-line no-descending-specificity */
.button-with-icon svg {
  --icon-size: 22px;
}

.button-with-icon {
  --font-size: var(--font-size-body-small);
  --padding: calc(var(--font-size) * 0.5);
  --surface-color: var(--color-black);
  --on-surface-color: var(--color-white);

  align-items: center;
  background-color: var(--surface-color);
  border-color: var(--color-electric-blue-300);
  border-radius: var(--rounded-corners-small);
  border-style: solid;
  border-width: 1px;
  box-shadow: var(--shadow-elevation-low);
  color: var(--on-surface-color);
  -moz-column-gap: calc(var(--icon-size) / 2);
       column-gap: calc(var(--icon-size) / 2);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-code-bold);
  font-size: var(--font-size);
  line-height: var(--font-size);

  /* margin-top: calc(var(--gutter) / 2); */
  padding-bottom: var(--padding);
  padding-left: calc(var(--padding) * 1.5);
  padding-right: calc(var(--padding) * 1.5);
  padding-top: calc(var(--padding));
  place-content: center center;
  text-align: center;
  text-shadow: none !important;
  transition-duration: var(--speed-fast), var(--speed-fast), var(--speed-fast);
  transition-property: background-color, box-shadow, color;
  transition-timing-function:
    var(--easing-color-or-opacity), var(--easing-color-or-opacity), var(--easing-color-or-opacity);
}

/* stylelint-disable-next-line no-descending-specificity */
.button-with-icon svg {
  fill: currentcolor;

  /* Optically align */

  /* margin-top: 0; */
  width: var(--icon-size);
}

.button-with-icon:hover {
  --surface-color: var(--color-electric-blue-300);
  --on-surface-color: var(--color-midnight-blue-900);

  /* background-image: none; */

  border-color: var(--color-midnight-blue-900);
  box-shadow: var(--shadow-elevation-high);
  transition-duration: var(--speed-extra-fast), var(--speed-extra-fast), var(--speed-extra-fast);
}

.button-with-icon:active,
.button-with-icon:focus {
  --surface-color: var(--color-electric-blue-600);

  background-image: none;
  border-color: var(--color-midnight-blue-900);
  box-shadow: var(--shadow-elevation-low);
  transition-property: none;
}

/* stylelint-disable declaration-no-important */
@charset 'UTF-8';

.meeting-agenda {
  border-bottom: 1px solid var(--color-electric-blue-800);
  padding-bottom: var(--gutter);
}

.meeting-agenda:last-of-type {
  border-bottom: none;
  padding-bottom: var(--gutter);
}

.meeting-agenda header {
  margin-bottom: var(--gutter);
}

/* Style for the definition list container */
.meeting-agenda dl {
  --definition-term-width: 20ch;

  display: grid;
  gap: var(--gutter) var(--gutter);

  /* grid-template-columns: max-content auto; */
  grid-template-columns: var(--definition-term-width) auto;
  margin: 0;
  padding: 0;
}

.meeting-agenda dt {
  --horizontal-padding: calc(var(--gutter) * 0.25);
  --surface-color: var(--color-midnight-blue-800);

  background-color: var(--surface-color);
  color: var(--color-white);
  font-family: var(--font-family-code-bold);

  /* Ensure each dt starts on a new row */
  margin: 0;
  padding-left: var(--horizontal-padding);
  padding-right: var(--horizontal-padding);
}

.meeting-agenda dd {
  margin: 0;
}

.meeting-agenda dd .moderator {
  display: block;
}

@media (width <= 480px) {
  .meeting-agenda header {
    margin-bottom: calc(var(--gutter) / 2);
  }

  .meeting-agenda dl {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    place-content: flex-start flex-start;
    row-gap: calc(var(--gutter) / 4);
  }

  .meeting-agenda dt {
    --surface-color: var(--color-black);
  }

  .meeting-agenda dd {
    margin-bottom: calc(var(--gutter) / 2);
  }

  .meeting-agenda dd:last-child,
  .meeting-agenda dd:only-child {
    margin-bottom: 0;
  }
}

/* ----------------------------------------------- */

.meeting-agenda dd ul {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  list-style-type: square;
  margin-bottom: calc(var(--gutter) / 2);
  margin-left: calc(var(--gutter) / 2);
  margin-right: 0;
  margin-top: calc(var(--gutter) / 2);
  padding: 0;
  place-content: flex-start flex-start;
  row-gap: calc(var(--gutter) / 2);
}

.meeting-agenda dd p {
  margin-bottom: 0;
  margin-top: 0;
}

/* ----------------------------------------------- */
.meeting-agenda dd .agenda-item-name,
/* stylelint-disable-next-line selector-max-compound-selectors */
.meeting-agenda dd .agenda-item-name svg {
  --icon-width: 20px;
}

.meeting-agenda dd .agenda-item-name {
  align-items: flex-start;
  -moz-column-gap: calc(var(--icon-width) / 2);
       column-gap: calc(var(--icon-width) / 2);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  line-height: var(--type-scale-perfect-fourth);
  place-content: center flex-start;
}

.meeting-agenda dd[data-is-session="true"] strong.agenda-item-name,
.meeting-agenda dd[data-is-keynote="true"] strong.agenda-item-name {
  color: var(--color-lemon-yellow-500);
}

/* stylelint-disable-next-line selector-max-compound-selectors */
.meeting-agenda dd .agenda-item-name svg {
  fill: currentcolor;
  flex-basis: var(--icon-width);
  flex-grow: 0;
  flex-shrink: 0;

  /* Optically align */
  margin-top: 1px;
  width: var(--icon-width);
}

/* stylelint-disable-next-line selector-max-compound-selectors */
.meeting-agenda dd .agenda-item-name svg.lightning-icon {
  margin-top: 3px;
  rotate: 24deg;
}

@charset 'UTF-8';

.hero-illustration {
  aspect-ratio: 16 / 9;
  position: relative;
}

.hero-illustration.cityscape .city-background {
  fill: var(--color-electric-blue-700);
}

.hero-illustration.cityscape .city-foreground {
  fill: var(--color-electric-blue-400);
}

.hero-illustration.cityscape .jmf-logo {
  fill: var(--color-ruby-red-700);
}

/* ----------------------------------------------- */
.text-link-with-illustration,
.text-link-with-illustration svg {
  display: block;
}

.text-link-with-illustration {
  border-bottom: 4px solid var(--color-midnight-blue-500);
  display: block;
}

.text-link-with-illustration .label {
  --position-xy: 0;

  font-size: var(--font-size-h6-fluid);
  left: var(--position-xy);
  position: absolute;
  top: var(--position-xy);
  z-index: 1;
}

@charset 'UTF-8';

.countdown-to-annual-meeting,
.countdown-to-annual-meeting .hero-illustration {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.countdown-to-annual-meeting {
  --horizontal-ruler-height: 1px;

  border-top: var(--horizontal-ruler-height) solid var(--color-electric-blue-700);
  margin-top: calc(var(--gutter) * 1.5 + var(--horizontal-ruler-height));
  padding-top: calc(var(--gutter) * 1.25);
}

.countdown-to-annual-meeting h2 {
  margin-top: 0;
}

.countdown-to-annual-meeting .hero-illustration {
  max-width: 768px;
}

@font-face {
font-family: 'houseFonts';
src: url(/_next/static/media/25a8a23efa9c14b1-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'houseFonts';
src: url(/_next/static/media/ea17e92aa0e3a98d-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'houseFonts';
src: url(/_next/static/media/3b8388c6d7dc311c-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'houseFonts';
src: url(/_next/static/media/6500a7bd15d11668-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'houseFonts';
src: url(/_next/static/media/cc6513b702eb8a14-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'houseFonts Fallback';src: local("Arial");ascent-override: 77.95%;descent-override: 20.91%;line-gap-override: 0.00%;size-adjust: 131.49%
}.__className_e81ffc {font-family: 'houseFonts', 'houseFonts Fallback', Helvetica, ui-sans-serif
}

