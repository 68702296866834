@charset 'UTF-8';

.viewport-surface {
  --position-xy: 0;

  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100vh;
  left: var(--position-xy);
  overflow: hidden;
  place-content: center center;
  pointer-events: none;
  position: fixed;
  top: var(--position-xy);
  width: 100vw;
  z-index: -2;
}

/* ----------------------------------------------- */
.antibodies,
.binary-code,
.word-grid {
  --size: 100%;
  --position-x: calc(50% - (var(--size) / 2));
  --position-y: calc(50% - (var(--size) / 2));

  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  height: var(--size);
  justify-content: center;
  left: var(--position-x);
  position: absolute;
  top: var(--position-y);
  width: var(--size);
}

/* ----------------------------------------------- */
.antibody-group-list {
  --size: 90vw;
  --radius-x: calc(var(--size) / 2.5); /* Horizontal radius */
  --radius-y: calc(var(--size) / 4); /* Vertical radius */

  display: grid;
  height: var(--size);
  list-style-type: none;
  margin: 0;
  opacity: 0;
  padding: 0;
  place-items: center;
  position: relative;
  transition-duration: var(--speed-normal);
  transition-property: opacity;
  transition-timing-function: var(--easing-color-or-opacity);
  width: var(--size);
}

[data-is-loaded="true"] .antibody-group-list {
  opacity: 1;
}

@media (width <= 1024px) {
  .antibody-group-list {
    --size: 110vw;
  }
}

@media (width <= 768px) {
  .antibody-group-list {
    --size: 140vw;
  }
}

@media (width <= 540px) {
  .antibody-group-list {
    --size: 80vh;
  }

  .word-grid {
    display: none;
  }
}

.antibody-group-list li {
  position: absolute;
}

.antibody-group-list li svg {
  --size: 52px;

  height: var(--size);
  width: var(--size);
}

.antibody-group-list li[data-icon="top-left"],
.antibody-group-list li[data-icon="top-right"],
.antibody-group-list li[data-icon="bottom-left"],
.antibody-group-list li[data-icon="bottom-right"] {
  --anchor-position: 0;
  --size: 152px;

  left: var(--anchor-position);
  position: fixed;
  top: var(--anchor-position);
}

.antibody-group-list li[data-icon="top-left"],
.antibody-group-list li[data-icon="top-right"] {
  top: var(--anchor-position);
}

.antibody-group-list li[data-icon="bottom-left"],
.antibody-group-list li[data-icon="bottom-right"] {
  bottom: var(--anchor-position);
  top: auto;
}

.antibody-group-list li[data-icon="top-right"],
.antibody-group-list li[data-icon="bottom-right"] {
  left: auto;
  right: var(--anchor-position);
}

.antibody-group-list li[data-icon="top-left"],
.antibody-group-list li[data-icon="bottom-left"] {
  left: var(--anchor-position);
}

.antibody-group-list li[data-icon="top-left"] {
  transform: translate(-50%, -50%);
}

.antibody-group-list li[data-icon="top-right"] {
  transform: translate(50%, -50%);
}

.antibody-group-list li[data-icon="bottom-left"] {
  transform: translate(-50%, 50%);
}

.antibody-group-list li[data-icon="bottom-right"] {
  transform: translate(50%, 50%);
}

/* ----------------------------------------------- */

/* Calculate angle increment based on 10 items (360 degrees / 10 items = 36 degrees per item) */
.antibody-group-list li[data-icon="1"] {
  transform: rotate(0deg) translate(calc(var(--size) / 2.5)) rotate(0deg);
}

[data-route="/meeting/"] .antibody-group-list li[data-icon="1"],
[data-route="/publishing/"] .antibody-group-list li[data-icon="1"],
[data-route="/about/"] .antibody-group-list li[data-icon="1"] {
  display: none;
}

.antibody-group-list li[data-icon="2"] {
  transform: rotate(36deg) translate(calc(var(--size) / 2.5)) rotate(-36deg);
}

[data-route="/"] .antibody-group-list li[data-icon="2"] {
  display: none;
}

.antibody-group-list li[data-icon="3"] {
  transform: rotate(72deg) translate(calc(var(--size) / 2.5)) rotate(-72deg);
}

.antibody-group-list li[data-icon="4"] {
  transform: rotate(108deg) translate(calc(var(--size) / 2.5)) rotate(-108deg);
}

.antibody-group-list li[data-icon="5"] {
  transform: rotate(144deg) translate(calc(var(--size) / 2.5)) rotate(-144deg);
}

[data-route="/"] .antibody-group-list li[data-icon="5"] {
  display: none;
}

.antibody-group-list li[data-icon="6"] {
  transform: rotate(180deg) translate(calc(var(--size) / 2.5)) rotate(-180deg);
}

.antibody-group-list li[data-icon="7"] {
  transform: rotate(216deg) translate(calc(var(--size) / 2.5)) rotate(-216deg);
}

.antibody-group-list li[data-icon="8"] {
  transform: rotate(252deg) translate(calc(var(--size) / 2.5)) rotate(-252deg);
}

.antibody-group-list li[data-icon="9"] {
  transform: rotate(288deg) translate(calc(var(--size) / 2.5)) rotate(-288deg);
}

.antibody-group-list li[data-icon="10"] {
  transform: rotate(324deg) translate(calc(var(--size) / 2.5)) rotate(-324deg);
}

/* ----------------------------------------------- */
.binary-code,
.binary-code li {
  --font-size: 12px;
}

.binary-code {
  --position-y: 0;

  align-items: flex-start;
  color: var(--color-midnight-blue-500);
  column-gap: calc(var(--font-size) / 4);
  font-family: var(--font-family-code-light);
  font-size: var(--font-size);
  line-height: var(--font-size);
  list-style: none;
  margin: 0;
  padding: 0;
  transform: translateY(-90%);
}

[data-is-loaded="true"] .binary-code {
  transform: translateY(0);
  transition-duration: var(--speed-slow);
  transition-property: transform;
  transition-timing-function: var(--easing-entering);
}

.binary-code li {
  align-self: auto;
  flex-basis: var(--font-size);
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

.binary-code li::before {
  --size: 100%;
  --position: 0;

  background: linear-gradient(to bottom, rgb(0 0 0 / 0%) 0%, var(--color-midnight-blue-900) 100%);
  content: "";
  height: var(--size);
  left: var(--position);
  pointer-events: none;
  position: absolute;
  top: var(--position);
  width: var(--size);
}

.binary-code em {
  display: block;
  font-style: normal;
}

.binary-code em.bright {
  color: var(--color-midnight-blue-400);
}

.binary-code em.electric {
  color: var(--color-electric-blue-400);
}

/* ----------------------------------------------- */
.word-grid-list {
  --size: 100%;

  color: var(--color-midnight-blue-400);
  display: grid;
  font-family: var(--font-family-code-light);
  font-size: var(--font-size-body-big-fluid);
  grid-gap: 0;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  height: var(--size);
  list-style: none;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  width: var(--size);
}

.word-grid-list li {
  display: grid;
  place-content: center;
}

.grid-cell-1 {
  grid-area: 2 / 1 / 3 / 2;
}

.grid-cell-2 {
  grid-area: 2 / 5 / 3 / 6;
}

.grid-cell-3 {
  grid-area: 4 / 1 / 5 / 2;
}

.grid-cell-4 {
  grid-area: 4 / 5 / 5 / 6;
}

.grid-cell-5 {
  grid-area: 5 / 2 / 6 / 3;
}

.grid-cell-6 {
  grid-area: 5 / 4 / 6 / 5;
}

.grid-cell-7 {
  grid-area: 1 / 2 / 2 / 3;
}

.grid-cell-8 {
  grid-area: 1 / 4 / 2 / 5;
}

/* ----------------------------------------------- */
.lightbox {
  --position-xy: 0;
  --surface-opacity: 0%;

  align-items: center;
  background-color: rgb(0 0 0 / var(--surface-opacity));
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: var(--position-xy);
  overflow: hidden;
  padding: var(--gutter);
  place-content: center center;
  pointer-events: auto;
  position: fixed;
  top: var(--position-xy);
  transition-property: background-color;
  width: 100vw;
  z-index: 1;
}

.lightbox-image-frame {
  background-color: var(--color-white);
  box-shadow: var(--shadow-elevation-high);
  display: inline-flex;
  padding: var(--gutter);
}

.lightbox img {
  --min-width: 196px;
  --max-width: 1242px;
  --preferred-width: 90vw;

  display: block;
  opacity: 0;
  transform: scale(0.25);
  transform-origin: center center;
  transition-property: opacity, transform;
  width: clamp(var(--min-width), var(--preferred-width), var(--max-width));
}

.lightbox[data-image-is-loaded="true"] {
  --surface-opacity: 75%;

  transition-duration: var(--speed-fast);
  transition-timing-function: var(--easing-color-or-opacity);
}

.lightbox[data-image-is-loaded="true"] img {
  opacity: 1;
  transform: scale(1);
  transition-duration: var(--speed-fast), var(--speed-extra-fast);
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-entering);
}
