/* stylelint-disable declaration-no-important */
@charset 'UTF-8';

button.lightbox-trigger {
  --outline-color: var(--color-electric-blue-700);
  --outline-width: 1px;
  --size: 100%;

  display: block;
  height: var(--size);
  outline-color: var(--outline-color) !important;
  outline-offset: calc(var(--outline-width) * 4) !important;
  outline-style: solid !important;
  outline-width: var(--outline-width) !important;
  transition-duration: var(--speed-fast);
  transition-property: outline-color;
  transition-timing-function: var(--easing-color-or-opacity);
  width: var(--size);
}

button.lightbox-trigger:hover {
  --outline-color: var(--color-electric-blue-300);
}

button.lightbox-trigger:active,
button.lightbox-trigger:focus {
  --outline-color: var(--color-electric-blue-100);

  transition-property: none;
}

/* ----------------------------------------------- */
.button-with-icon,
/* stylelint-disable-next-line no-descending-specificity */
.button-with-icon svg {
  --icon-size: 22px;
}

.button-with-icon {
  --font-size: var(--font-size-body-small);
  --padding: calc(var(--font-size) * 0.5);
  --surface-color: var(--color-black);
  --on-surface-color: var(--color-white);

  align-items: center;
  background-color: var(--surface-color);
  border-color: var(--color-electric-blue-300);
  border-radius: var(--rounded-corners-small);
  border-style: solid;
  border-width: 1px;
  box-shadow: var(--shadow-elevation-low);
  color: var(--on-surface-color);
  column-gap: calc(var(--icon-size) / 2);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-code-bold);
  font-size: var(--font-size);
  line-height: var(--font-size);

  /* margin-top: calc(var(--gutter) / 2); */
  padding-bottom: var(--padding);
  padding-left: calc(var(--padding) * 1.5);
  padding-right: calc(var(--padding) * 1.5);
  padding-top: calc(var(--padding));
  place-content: center center;
  text-align: center;
  text-shadow: none !important;
  transition-duration: var(--speed-fast), var(--speed-fast), var(--speed-fast);
  transition-property: background-color, box-shadow, color;
  transition-timing-function:
    var(--easing-color-or-opacity), var(--easing-color-or-opacity), var(--easing-color-or-opacity);
}

/* stylelint-disable-next-line no-descending-specificity */
.button-with-icon svg {
  fill: currentcolor;

  /* Optically align */

  /* margin-top: 0; */
  width: var(--icon-size);
}

.button-with-icon:hover {
  --surface-color: var(--color-electric-blue-300);
  --on-surface-color: var(--color-midnight-blue-900);

  /* background-image: none; */

  border-color: var(--color-midnight-blue-900);
  box-shadow: var(--shadow-elevation-high);
  transition-duration: var(--speed-extra-fast), var(--speed-extra-fast), var(--speed-extra-fast);
}

.button-with-icon:active,
.button-with-icon:focus {
  --surface-color: var(--color-electric-blue-600);

  background-image: none;
  border-color: var(--color-midnight-blue-900);
  box-shadow: var(--shadow-elevation-low);
  transition-property: none;
}
