/* stylelint-disable no-descending-specificity */
/* stylelint-disable declaration-no-important */
@charset 'UTF-8';

.article-date-publisher-and-doi-container,
.article-date-publisher-and-doi-container-cell,
.publisher-logo-container {
  --logo-basis: 160px;
}

/* ----------------------------------------------- */
.scholarly-article:not(:first-of-type) {
  border-top: 1px solid var(--color-electric-blue-700);
  margin-top: calc(var(--gutter) * 1.1);
}

.scholarly-article a,
.scholarly-article time,
.scholarly-article dl[itemtype="http://schema.org/Periodical"] dd[itemprop="name"]
{
  color: var(--color-electric-blue-300);
}

.scholarly-article [itemprop="headline"] {
  color: var(--color-white);
  font-family: var(--font-family-code-medium);
  font-size: var(--font-size-h6-fluid);
  line-height: var(--type-scale-golden-ratio);
}

.scholarly-article ul.authors,
.scholarly-article dl[itemtype="http://schema.org/Periodical"],
.scholarly-article dl.doi {
  --gap: calc(var(--gutter) / 8);

  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap) var(--gap);
  list-style-type: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: var(--gap);
  padding: 0;
  place-content: flex-start flex-start;
  text-shadow: var(--text-shadow-black);
}

.scholarly-article dl[itemtype="http://schema.org/Periodical"].article-date-and-publisher
{
  flex-direction: column;
  margin-top: calc(var(--gap) * 1.75 * -1);
}

.scholarly-article ul.authors {
  row-gap: calc(var(--gap) / 2);
}

.scholarly-article ul.authors li,
.scholarly-article dl[itemtype="http://schema.org/Periodical"] dt,
.scholarly-article dl[itemtype="http://schema.org/Periodical"] dd,
.scholarly-article dl.doi {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.scholarly-article ul.authors li:not(:last-child)::after {
  content: ",";
}

/* ----------------------------------------------- */
.scholarly-article dl[itemtype="http://schema.org/Periodical"],
.scholarly-article dl[itemtype="http://schema.org/Periodical"] dd
{
  position: relative;
}

.scholarly-article dl[itemtype="http://schema.org/Periodical"] dt
{
  display: none;
}

.scholarly-article dl[itemtype="http://schema.org/Periodical"] dd[itemprop="name"]
{
  flex-shrink: 1;
  font-family: var(--font-family-code-medium-italic);
}

/* ----------------------------------------------- */

.scholarly-article dl[itemtype="http://schema.org/Periodical"] dd.publisher-logo
{
  flex-basis: 100%;
  padding-bottom: calc(var(--gutter) / 2);
  padding-top: calc(var(--gutter) / 2);
}

.publisher-logo-container {
  --horizontal-freespace: calc(var(--gutter) / 2);

  align-items: center;
  aspect-ratio: 2 / 1;
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: var(--logo-basis);
  padding-left: var(--horizontal-freespace);
  padding-right: var(--horizontal-freespace);
  place-content: center center;
}

/* ----------------------------------------------- */
.scholarly-article [itemprop="headline"] a {
  --surface-color: var(--color-black);
  --on-surface-color: var(--color-electric-blue-400);

  background-color: var(--surface-color);
  border-bottom: 2px solid var(--surface-color);
  color: var(--on-surface-color);
  display: inline;
  position: relative;
  transition-duration: var(--speed-fast);
  transition-property: color;
  transition-timing-function: var(--easing-color-or-opacity);
}

.scholarly-article [itemprop="headline"] a:hover {
  --on-surface-color: var(--color-white);

  transition-duration: var(--speed-extra-fast);
}

.scholarly-article [itemprop="headline"] a:active,
.scholarly-article [itemprop="headline"] a:focus {
  --on-surface-color: var(--color-lemon-yellow-500);

  transition-property: none;
}

.article-date-publisher-and-doi-container {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: calc(var(--gutter) / 2) var(--gutter);
  margin-top: var(--gutter);
  place-content: center flex-start;
}

.article-date-publisher-and-doi-container-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  order: 1;
}

.article-date-publisher-and-doi-container-cell-publisher-logo {
  flex-basis: var(--logo-basis);
  flex-grow: 0;
  order: 0;
}

/* ----------------------------------------------- */
@media (width <= 1024px) {
  .article-date-publisher-and-doi-container {
    flex-direction: column;
    margin-top: calc(var(--gutter) / 2);
  }

  .article-date-publisher-and-doi-container-cell {
    order: 0;
  }

  .article-date-publisher-and-doi-container-cell-publisher-logo {
    flex-basis: auto;
    order: 1;
  }
}

/* ----------------------------------------------- */
.scholarly-article-image-gallery,
.scholarly-article-image-gallery li {
  --gap: calc(var(--gutter) / 2);
  --images-per-row: 3;
}

.scholarly-article-image-gallery {
  align-items: center;
  background-color: var(--color-black);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap) var(--gap);
  list-style: none;
  margin-top: calc(var(--gutter) * 1.5);
  padding: var(--gap);
  place-content: stretch flex-start;
}

.scholarly-article-image-gallery li {
  --gallery-image-width: calc(100% / var(--images-per-row) - var(--gap) * 0.68);

  align-self: auto;
  aspect-ratio: 2 / 1;
  flex-basis: var(--gallery-image-width);
  flex-grow: 0;
  flex-shrink: 1;
  position: relative;
  width: var(--gallery-image-width);
}

.scholarly-article-image-gallery img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  display: inline-flex;
  height: var(--media-size) !important;
  left: var(--media-position);
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size) !important;
}

/* ----------------------------------------------- */
.scholarly-article-image-gallery a {
  --size: 100%;

  background-color: var(--color-white);
  border-color: var(--color-white);
  border-style: solid;
  border-width: 1px;
  display: block;
  height: var(--size);
  position: relative;
  width: var(--size);
}

.scholarly-article-image-gallery a:hover,
.scholarly-article-image-gallery a:active,
.scholarly-article-image-gallery a:focus {
  border-color: var(--color-electric-blue-300);
}

@media (width <= 414px) {
  .scholarly-article-image-gallery {
    display: none;
  }
}
