@charset 'UTF-8';

.input-helper {
  display: block;
  font-size: var(--font-size-body-small);
  line-height: var(--type-scale-perfect-fourth);
  padding-top: calc(var(--gutter) / 4);
}

/* ----------------------------------------------- */
button[type="submit"] {
  --font-size: var(--font-size-h6-fluid);
  --gap: calc(var(--gutter) / 2);
  --surface-color: var(--color-ruby-red-700);
  --on-surface-color: var(--color-white);

  align-items: center;
  background-color: var(--surface-color);
  background-image: linear-gradient(
    0deg,
    hsl(336deg 100% 25%) 0%,
    hsl(337deg 100% 28%) 29%,
    hsl(339deg 98% 32%) 43%,
    hsl(342deg 93% 36%) 57%,
    hsl(345deg 87% 41%) 71%,
    hsl(349deg 81% 46%) 100%
  );
  background-repeat: no-repeat;
  border-color: var(--color-ruby-red-600);
  border-radius: var(--rounded-corners-medium);
  border-style: solid;
  border-width: 2px;
  box-shadow: var(--shadow-elevation-medium);
  color: var(--on-surface-color);
  column-gap: var(--gap);
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-code-semi-bold);
  font-size: var(--font-size);
  line-height: var(--font-size);
  margin-left: auto;
  margin-right: auto;
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
  overflow: hidden;
  padding: var(--gap);
  place-content: center center;
  position: relative;
  text-align: center;
  text-shadow: var(--text-shadow-button-primary);
  transition-duration: var(--speed-fast), var(--speed-normal);
  transition-property: background-color, box-shadow;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity);
  white-space: nowrap;
}

button[data-is-disabled="true"] {
  pointer-events: none;
}

button[type="submit"]:hover,
button[type="submit"]:active,
button[type="submit"]:focus {
  text-shadow: none;
}

button[type="submit"]:hover {
  --surface-color: var(--color-ruby-red-500);

  background-image: none;
  border-color: var(--color-ruby-red-700);
  box-shadow: var(--shadow-elevation-high);
  transition-duration: var(--speed-extra-fast), var(--speed-fast);
}

button[type="submit"]:active,
button[type="submit"]:focus {
  --surface-color: var(--color-ruby-red-700);

  box-shadow: var(--shadow-elevation-low);
  transition-property: none;
}

/* ----------------------------------------------- */
.newsletter-subscribe-form button[type="submit"] {
  /* Optically offset */
  margin-top: -1px;
}

/* ----------------------------------------------- */
button svg {
  fill: currentcolor;
}

/* ----------------------------------------------- */
label {
  display: block;
  position: relative;
}

label[data-is-error="true"]::after {
  --font-size: var(--font-size-body-small);

  bottom: calc(var(--font-size) * -1.5);
  color: var(--color-yellow-900);
  content: attr(data-error-message);
  font-family: var(--font-family-code-medium);
  font-size: var(--font-size);
  left: 0;
  line-height: var(--font-size);
  position: absolute;
}

/* ----------------------------------------------- */
label span {
  cursor: pointer;
  display: inline-block;
}

fieldset label span {
  text-shadow: var(--text-shadow-white);
}

.checkbox-label,
.checkbox-label span,
.radio-label,
.radio-label span,
input[type="checkbox"],
input[type="radio"] {
  --input-size: 18px;
}

/* ----------------------------------------------- */
fieldset {
  --freespace: calc(var(--gutter) / 2);

  background-color: var(--color-dark-gray-050);
  border-color: var(--color-cool-gray-600);
  border-radius: var(--rounded-corners-medium);
  border-style: solid;
  border-width: 2px;
  box-shadow: var(--shadow-elevation-low);
  margin-bottom: var(--freespace);
  margin-top: calc(var(--freespace) * 2);
  padding-bottom: var(--freespace);
  padding-left: var(--freespace);
  padding-right: var(--freespace);
  padding-top: calc(var(--freespace) / 4);
}

/* ----------------------------------------------- */
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="number"]::placeholder,
select::placeholder {
  color: var(--color-neutral-800);
  opacity: 1; /* Firefox */
}

input[type="text"],
input[type="email"],
input[type="number"],
select,
textarea {
  --font-size: var(--font-size-body);
  --surface-color: var(--color-white);
  --decoration-color: var(--color-white);
  --decoration-width: 4px;
  --on-surface-color: var(--color-black);
  --horizontal-padding: calc(var(--font-size) / 2);

  background-color: var(--surface-color);
  border-color: var(--decoration-color);

  /* border-radius: var(--rounded-corners-small); */
  border-style: solid;
  border-width: var(--decoration-width);
  color: var(--color-black);
  display: block;
  font-family: var(--font-family-regular);
  font-size: var(--font-size);
  line-height: var(--font-size);
  min-height: calc(var(--font-size) * 2.5 + var(--decoration-width) * 1);
  outline: none;
  padding-bottom: 0;
  padding-left: var(--horizontal-padding);
  padding-right: var(--horizontal-padding);
  padding-top: 1px;
  width: 100%;
}

input[autoComplete="postal-code"] {
  max-width: 20ch;
}

/* ----------------------------------------------- */
textarea {
  min-height: 80px;
}

/* ----------------------------------------------- */
.button-group {
  --gap: var(--gutter);

  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: calc(var(--gap) / 2) var(--gap);
  list-style: none;
  margin: 0;
  padding: 0;
  place-content: center flex-start;
}

.button-group li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

/* ----------------------------------------------- */
.newsletter-subscribe-form input[type="text"],
.newsletter-subscribe-form input[type="email"] {
  font-family: var(--font-family-code-medium);
  min-width: 200px;
  transition-duration: var(--speed-normal);
  transition-property: box-shadow;
  transition-timing-function: var(--easing-exiting);
}

.newsletter-subscribe-form input[type="text"]:focus,
.newsletter-subscribe-form input[type="email"]:focus {
  --decoration-color: var(--color-electric-blue-500);

  box-shadow: var(--shadow-elevation-medium);
  transition-duration: var(--speed-fast);
  transition-timing-function: var(--easing-entering);
}

/* ----------------------------------------------- */
.error-message {
  display: block;
  font-family: var(--font-family-bold);
  font-size: var(--font-size-body-small);
  margin-top: calc(var(--gap) / 2);
  pointer-events: none;
}

/* ----------------------------------------------- */
.newsletter-subscribe-form {
  position: relative;
}

.newsletter-subscribe-form legend {
  color: var(--color-white);
  line-height: var(--type-scale-golden-ratio);

  /* margin-top: 0; */
  max-width: 60ch;
  text-shadow: var(--text-shadow-black);
}

/* ----------------------------------------------- */
.newsletter-subscribe-form-elements {
  --gap: calc(var(--gutter) / 2);

  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 var(--gap);
  margin-top: calc(var(--gap) * 2);
  place-content: center space-between;
}

.newsletter-subscribe-form-elements-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.newsletter-subscribe-form-elements-cell-submit {
  flex-grow: 0;
  flex-shrink: 0;
}

/* ----------------------------------------------- */
@media (width <= 375px) {
  .newsletter-subscribe-form-elements-cell {
    flex-basis: 100%;
  }
}

/* ----------------------------------------------- */
.button-group.is-form-submit {
  margin-top: var(--gutter);
}
