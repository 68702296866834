@charset 'UTF-8';

.subscribe-sheet,
.subscribe-sheet::after {
  --sheet-height: auto;
  --sheet-width: 100%;
}

.subscribe-sheet {
  --position-xy: 0;

  background-color: var(--color-neutral-900);
  height: var(--sheet-height);
  left: var(--position-xy);
  padding-bottom: calc(var(--gutter) / 2);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  padding-top: calc(var(--gutter) / 2);
  pointer-events: none;
  position: fixed;
  top: var(--position-xy);
  transform: translateY(-100%);
  transition-duration: var(--speed-fast);
  transition-property: transform;
  transition-timing-function: var(--easing-exiting);
  width: var(--sheet-width);
  z-index: 1000;
}

.subscribe-sheet::after {
  --curtain-height: calc(var(--gutter) * 0.5);

  background: var(--window-shade);
  background-repeat: no-repeat;
  background-size: var(--sheet-width) var(--curtain-height);
  bottom: calc(var(--curtain-height) * -1);
  content: "";
  height: var(--curtain-height);
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: auto;
  transition-duration: var(--speed-fast);
  transition-property: opacity;
  transition-timing-function: var(--easing-color-or-opacity);
  width: var(--sheet-width);
}

/* ----------------------------------------------- */
.subscribe-sheet[data-is-visible="true"] {
  pointer-events: auto;
  transform: translateY(0);
  transition-duration: var(--speed-fast);
  transition-timing-function: var(--easing-entering);
}

.subscribe-sheet[data-is-visible="true"]::after {
  opacity: 1;
}

/* ----------------------------------------------- */
.subscribe-success-message {
  max-width: 90ch;
}

.subscribe-sheet .subscribe-success-message,
.subscribe-sheet[data-is-show-subscribe-success="true"] .newsletter-subscribe-form {
  display: none;
}

.subscribe-sheet[data-is-show-subscribe-success="true"] .subscribe-success-message {
  display: block;
}
