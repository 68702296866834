/* stylelint-disable declaration-no-important */
@charset 'UTF-8';

:root {
  --animation-delay-short: 0.1s;
  --animation-delay-medium: 0.5s;
  --color-black: black;
  --color-white: white;
  --color-neutral-900: #212121;
  --color-neutral-800: #424242;
  --color-neutral-700: #616161;
  --color-neutral-600: #757575;
  --color-neutral-500: #9e9e9e;
  --color-neutral-400: #bdbdbd;
  --color-neutral-300: #e0e0e0;
  --color-neutral-200: #eee;
  --color-neutral-100: whitesmoke;
  --color-neutral-050: #fafafa;

  /* Base: 900 */
  --color-midnight-blue-900: #12264b;
  --color-midnight-blue-800: #1a3663;
  --color-midnight-blue-700: #203f70;
  --color-midnight-blue-600: #28497b;
  --color-midnight-blue-500: #2f5083;
  --color-midnight-blue-400: #4f6893;
  --color-midnight-blue-300: #6e82a4;
  --color-midnight-blue-200: #96a5be;
  --color-midnight-blue-100: #bfc8d9;
  --color-midnight-blue-050: #e6e9ef;
  --color-electric-blue-900: #00656c;
  --color-electric-blue-800: #008898;
  --color-electric-blue-700: #009cb1;
  --color-electric-blue-600: #00b1cb;
  --color-electric-blue-500: #00c1df;

  /* Base: 400 */
  --color-electric-blue-400: #09cae4;
  --color-electric-blue-300: #46d4ea;
  --color-electric-blue-200: #7ee1f1;
  --color-electric-blue-100: #b2edf6;
  --color-electric-blue-050: #e0f8fc;
  --color-lemon-yellow-900: #fe7f00;
  --color-lemon-yellow-800: #fa0;
  --color-lemon-yellow-700: #fec302;
  --color-lemon-yellow-600: #ffdc14;

  /* Base: 500 */
  --color-lemon-yellow-500: #fff01d;
  --color-lemon-yellow-400: #f9ed43;
  --color-lemon-yellow-300: #fcf26a;
  --color-lemon-yellow-200: #fef696;
  --color-lemon-yellow-100: #fefac1;
  --color-lemon-yellow-050: #fffee6;
  --color-ruby-red-900: #7e0033;
  --color-ruby-red-800: #a00037;

  /* Base: 700 */
  --color-ruby-red-700: #b30838;
  --color-ruby-red-600: #c7103b;
  --color-ruby-red-500: #d6173b;
  --color-ruby-red-400: #de3755;
  --color-ruby-red-300: #e75971;
  --color-ruby-red-200: #f08898;
  --color-ruby-red-100: #f7b6c1;
  --color-ruby-red-050: #fce2e6;
  --color-green-1000: #002e1b;
  --color-green-900: #005532;
  --color-green-800: #007448;
  --color-green-700: #008555;
  --color-green-600: #009762;
  --color-green-500: #00a66d;

  /* Base: 400 */
  --color-green-400: #08b383;
  --color-green-300: #51c098;
  --color-green-200: #89d1b4;
  --color-green-100: #b8e3d1;
  --color-green-050: #e2f4ed;
  --window-shade: linear-gradient(
    180deg,
    var(--color-midnight-blue-900) 0%,
    rgb(255 255 255 / 0%) 100%
  );
  --font-family-serif-medium: "eiko/medium", serif;
  --font-family-code-light: "IBMPlexMono/light", system-ui, helvetica, arial, sans-serif;
  --font-family-code-medium: "IBMPlexMono/medium", system-ui, helvetica, arial, sans-serif;
  --font-family-code-regular: "IBMPlexMono/regular", system-ui, helvetica, arial, sans-serif;
  --font-family-code-bold: "IBMPlexMono/bold", system-ui, helvetica, arial, sans-serif;
  --font-family-code-semi-bold: "IBMPlexMono/semi-bold", system-ui, helvetica, arial, sans-serif;
  --font-family-code-italic: "IBMPlexMono/italic", system-ui, helvetica, arial, sans-serif;
  --font-family-code-medium-italic:
    "IBMPlexMono/medium-italic", system-ui, helvetica, arial, sans-serif;
  --font-size-h1: 96px;
  --font-size-h2: 60px;
  --font-size-h3: 48px;
  --font-size-h4: 34px;
  --font-size-h5: 25px;
  --font-size-h6: 20px;
  --font-size-body: 16px;
  --font-size-body-big: 23px;
  --font-size-body-small: 14px;
  --font-size-body-extra-small: 12px;
  --font-size-h1-fluid: clamp(3.355rem, 2.64rem + 3.586vw, 6.941rem);
  --font-size-h2-fluid: clamp(2.684rem, 2.178rem + 2.53vw, 5.214rem);
  --font-size-h3-fluid: clamp(2.145rem, 1.793rem + 1.76vw, 3.905rem);
  --font-size-h4-fluid: clamp(1.716rem, 1.474rem + 1.21vw, 2.936rem);
  --font-size-h5-fluid: clamp(1.375rem, 1.21rem + 0.825vw, 2.2rem);
  --font-size-h6-fluid: clamp(1.1rem, 0.99rem + 0.55vw, 1.65rem);
  --font-size-body-fluid: clamp(0.88rem, 0.814rem + 0.363vw, 1.243rem);
  --font-size-body-big-fluid: clamp(0.968rem, 0.8954rem + 0.3993vw, 1.3673rem);
  --font-size-body-small-fluid: clamp(0.704rem, 0.66rem + 0.22vw, 0.924rem);
  --gutter: 2rem;
  --text-shadow-white: hsl(0deg 0% 100% / 75%) 0 1px 1px;
  --text-shadow-black: hsl(0deg 0% 0% / 75%) 0 1px 1px;
  --text-shadow-electric-blue: hsl(0deg 0% 0% / 75%) 0 1px 1px;
  --text-shadow-button-primary: hsl(349deg 100% 13% / 75%) 0 1px 1px;
}

@font-face {
  font-display: fallback;
  font-family: "eiko/medium";
  src: url("/fonts/eiko/medium.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "IBMPlexMono/bold";
  src: url("/fonts/IBMPlexMono/bold.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "IBMPlexMono/semi-bold";
  src: url("/fonts/IBMPlexMono/semi-bold.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "IBMPlexMono/medium";
  src: url("/fonts/IBMPlexMono/medium.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "IBMPlexMono/medium-italic";
  src: url("/fonts/IBMPlexMono/medium-italic.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "IBMPlexMono/regular";
  src: url("/fonts/IBMPlexMono/regular.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "IBMPlexMono/italic";
  src: url("/fonts/IBMPlexMono/italic.woff2") format("woff2");
}

[data-document-ready="false"] *,
[data-document-ready="false"] *::after,
[data-document-ready="false"] *::before {
  animation-play-state: paused !important;
}

@media (prefers-reduced-motion) {
  [data-document-ready="true"] *,
  [data-document-ready="true"] *::after,
  [data-document-ready="true"] *::before {
    animation-play-state: paused !important;
  }
}

body {
  background-color: var(--color-midnight-blue-900);
  color: var(--color-white);
  font-family: var(--font-family-code-regular);
  font-size: var(--font-size-body);
}

/* ----------------------------------------------- */
.application-shell {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100%;
  place-content: center flex-start;
}

.application-shell-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.application-shell-cell-header,
.application-shell-cell-body,
.application-shell-cell-footer {
  width: 100%;
}

.application-shell-cell-body,
.application-shell-cell-footer {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.application-shell-cell-body {
  /* align-content: center; */
  align-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
}

.application-shell-cell-body.home {
  align-content: center;
  padding-bottom: 2vh;
}

.application-shell-cell-body.align-left {
  text-align: left;
}

/* ----------------------------------------------- */
.single-type,
.single-type-cell {
  --column-gap: calc(var(--gutter) * 2);
}

@media (width <= 768px) {
  .single-type,
  .single-type-cell {
    --column-gap: var(--gutter);
  }
}

.single-type {
  align-items: stretch;
  column-gap: var(--column-gap);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  max-width: 1024px;
  place-content: stretch flex-start;
}

.single-type-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.single-type-cell-content {
  --default-width: 66.6%;

  flex-basis: calc(var(--default-width) - var(--column-gap));
  order: 1;
}

.single-type-cell-hero-media {
  --min-width: 100px;
  --max-width: 220px;
  --preferred-width: calc((var(--min-width) + var(--max-width)) / 2);

  flex-basis: calc(var(--preferred-width) - var(--column-gap));
  max-width: var(--max-width);
  min-width: var(--min-width);
  order: 0;
}

/* ----------------------------------------------- */
.single-type .hero-media-container {
  aspect-ratio: 1 / 1;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  place-items: center;
  position: relative;
}

main.single-type {
  padding-bottom: calc(var(--gutter) * 2);
}

@media (width <= 768px) {
  .single-type-cell-content {
    --default-width: 75%;
  }
}

@media (width <= 540px) {
  .single-type {
    flex-direction: column;
    margin-top: var(--gutter);
  }

  .single-type-cell-hero-media {
    --max-width: unset;

    flex-basis: 100%;
    padding-bottom: var(--gutter);
  }

  .single-type .hero-media-container {
    max-width: 180px;
  }
}

@media (width <= 414px) {
  .single-type-cell-hero-media {
    display: none;
  }

  .single-type {
    margin-top: 0;
  }

  .article-date-publisher-and-doi-container-cell-publisher-logo {
    display: none;
  }
}

/* ----------------------------------------------- */
.button-unstyled,
.button-unstyled:hover,
.button-unstyled:active,
.button-unstyled:focus {
  appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  transition-property: none;
}

/* ----------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
legend {
  /* font-family: var(--font-family-serif-medium); */
  font-family: var(--font-family-code-medium);
  text-shadow: var(--text-shadow-black);
}

h2,
h3,
h4,
h5,
h6,
legend,
p,
ul,
ol,
hr {
  --vertical-margin: var(--gutter);
}

h1 {
  font-size: var(--font-size-h4-fluid);
  line-height: var(--type-scale-major-second);
}

h2 {
  color: var(--color-electric-blue-300);
  font-size: var(--font-size-h5-fluid);
  margin-top: var(--vertical-margin);
}

legend,
p.header {
  font-size: var(--font-size-h6-fluid);
  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
}

p,
ul,
ol,
dl {
  font-family: var(--font-family-code-regular);
  font-size: var(--font-size-body-fluid);
  line-height: var(--type-scale-golden-ratio);
  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
}

strong {
  color: var(--color-electric-blue-400);
  font-family: var(--font-family-code-bold);
  font-style: normal;
}

em {
  font-family: var(--font-family-code-italic);
  font-style: normal;
}

/* ----------------------------------------------- */
p.lead-paragraph,
.single-type-cell-content {
  text-shadow: var(--text-shadow-black);
}

p.lead-paragraph {
  font-family: var(--font-family-code-medium);
  font-size: var(--font-size-body-big-fluid);
}

@media (width <= 480px) {
  p.lead-paragraph {
    text-align: unset;
  }
}

/* ----------------------------------------------- */
.level-1-heading-container-with-sharing-options {
  --gap: var(--gutter);

  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: calc(var(--gutter) / 2) var(--gutter);
  place-content: flex-start flex-start;
}

.level-1-heading-container-with-sharing-options .cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  order: 1;
  width: 100%;
}

.level-1-heading-container-with-sharing-options .sharing-options {
  order: 0;
}

.level-1-heading-container-with-sharing-options .social.share-buttons::before {
  display: none;
}

/* ----------------------------------------------- */
hr {
  background-color: var(--color-midnight-blue-500);
  border: none;
  height: 3px;
  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
  padding: 0;
  width: 100%;
}

/* ----------------------------------------------- */
a,
button {
  cursor: pointer;
}

/* ----------------------------------------------- */
a[aria-current="page"] {
  pointer-events: none;
}

/* ----------------------------------------------- */
a.inline-text-link,
.text-link-with-illustration .label {
  --decoration-height: 2px;
  --decoration-color: var(--color-electric-blue-400);

  background-color: transparent;
  background-image: linear-gradient(
    to right,
    var(--decoration-color) 0,
    var(--decoration-color) 100%
  );
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 0 var(--decoration-height);
  color: var(--color-electric-blue-300);
  display: inline;
  padding-bottom: calc(var(--decoration-height) * 2);
  padding-top: var(--decoration-height);
  transition-duration: var(--speed-fast);
  transition-property: background-size;
  transition-timing-function: var(--easing-exiting);
}

a.inline-text-link[data-color-scheme="yellow"],
.text-link-with-illustration[data-color-scheme="yellow"] .label {
  --decoration-color: var(--color-lemon-yellow-500);

  color: var(--color-lemon-yellow-500);
}

a.inline-text-link:hover,
.text-link-with-illustration:hover .label {
  background-size: 100% var(--decoration-height);
}

a.inline-text-link:active,
a.inline-text-link:focus,
.text-link-with-illustration:active .label,
.text-link-with-illustration:focus .label {
  --decoration-color: transparent;

  background-color: var(--color-black);
  color: var(--color-white);
}

/* ----------------------------------------------- */
.spirit-analyzer-logo-container {
  --min-width: 152px;
  --max-width: 375px;
  --preferred-width: 100%;
  --freespace: calc(var(--gutter) / 3);

  align-items: center;
  background-color: var(--color-midnight-blue-900);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: calc(var(--freespace) * -1);
  padding: var(--freespace);
  place-content: center center;
  width: clamp(var(--min-width), var(--preferred-width), var(--max-width));
}

.spirit-analyzer-logo {
  --descriptor-color: var(--color-white);

  width: 100%;
}

.spirit-analyzer-logo .blue {
  /* fill: #abd5f2; */

  /* fill: var(--color-electric-blue-050); */
  fill: var(--color-white);
}

.spirit-analyzer-logo .red {
  fill: var(--color-ruby-red-700);
}

.spirit-analyzer-logo .text {
  fill: var(--descriptor-color);
}

@media (width <= 414px) {
  .spirit-analyzer-logo-container {
    margin-left: 0;
  }
}

/* ----------------------------------------------- */
.close-button,
.close-button svg {
  --on-surface-color: var(--color-neutral-200);
}

.close-button {
  --size: 36px;
  --surface-color: var(--color-black);

  background-color: var(--surface-color);
  border-radius: 100%;
  color: var(--on-surface-color);
  display: grid;
  height: var(--size);
  line-height: unset;
  place-items: center center;
  width: var(--size);
}

.subscribe-sheet .close-button {
  position: absolute;
  right: calc(var(--gutter) / 2);
}

.close-button svg {
  --icon-size: 100%;

  fill: currentcolor;
  height: var(--icon-size);
  transform-origin: center;
  transition-duration: var(--speed-normal);
  transition-property: transform;
  transition-timing-function: var(--easing-snap);
  width: var(--icon-size);
}

.close-button:hover svg,
.close-button:active svg,
.close-button:focus svg {
  --on-surface-color: var(--color-white);
}

.close-button:hover svg {
  transform: scale(0.8);
  transition-duration: var(--speed-extra-fast);
}

.close-button:active svg,
.close-button:focus svg {
  transform: none;
  transition-property: none;
}

.close-button:focus {
  outline: none;
}
