/* stylelint-disable declaration-no-important */
@charset 'UTF-8';

.meeting-agenda {
  border-bottom: 1px solid var(--color-electric-blue-800);
  padding-bottom: var(--gutter);
}

.meeting-agenda:last-of-type {
  border-bottom: none;
  padding-bottom: var(--gutter);
}

.meeting-agenda header {
  margin-bottom: var(--gutter);
}

/* Style for the definition list container */
.meeting-agenda dl {
  --definition-term-width: 20ch;

  display: grid;
  gap: var(--gutter) var(--gutter);

  /* grid-template-columns: max-content auto; */
  grid-template-columns: var(--definition-term-width) auto;
  margin: 0;
  padding: 0;
}

.meeting-agenda dt {
  --horizontal-padding: calc(var(--gutter) * 0.25);
  --surface-color: var(--color-midnight-blue-800);

  background-color: var(--surface-color);
  color: var(--color-white);
  font-family: var(--font-family-code-bold);

  /* Ensure each dt starts on a new row */
  margin: 0;
  padding-left: var(--horizontal-padding);
  padding-right: var(--horizontal-padding);
}

.meeting-agenda dd {
  margin: 0;
}

.meeting-agenda dd .moderator {
  display: block;
}

@media (width <= 480px) {
  .meeting-agenda header {
    margin-bottom: calc(var(--gutter) / 2);
  }

  .meeting-agenda dl {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    place-content: flex-start flex-start;
    row-gap: calc(var(--gutter) / 4);
  }

  .meeting-agenda dt {
    --surface-color: var(--color-black);
  }

  .meeting-agenda dd {
    margin-bottom: calc(var(--gutter) / 2);
  }

  .meeting-agenda dd:last-child,
  .meeting-agenda dd:only-child {
    margin-bottom: 0;
  }
}

/* ----------------------------------------------- */

.meeting-agenda dd ul {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  list-style-type: square;
  margin-bottom: calc(var(--gutter) / 2);
  margin-left: calc(var(--gutter) / 2);
  margin-right: 0;
  margin-top: calc(var(--gutter) / 2);
  padding: 0;
  place-content: flex-start flex-start;
  row-gap: calc(var(--gutter) / 2);
}

.meeting-agenda dd p {
  margin-bottom: 0;
  margin-top: 0;
}

/* ----------------------------------------------- */
.meeting-agenda dd .agenda-item-name,
/* stylelint-disable-next-line selector-max-compound-selectors */
.meeting-agenda dd .agenda-item-name svg {
  --icon-width: 20px;
}

.meeting-agenda dd .agenda-item-name {
  align-items: flex-start;
  column-gap: calc(var(--icon-width) / 2);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  line-height: var(--type-scale-perfect-fourth);
  place-content: center flex-start;
}

.meeting-agenda dd[data-is-session="true"] strong.agenda-item-name,
.meeting-agenda dd[data-is-keynote="true"] strong.agenda-item-name {
  color: var(--color-lemon-yellow-500);
}

/* stylelint-disable-next-line selector-max-compound-selectors */
.meeting-agenda dd .agenda-item-name svg {
  fill: currentcolor;
  flex-basis: var(--icon-width);
  flex-grow: 0;
  flex-shrink: 0;

  /* Optically align */
  margin-top: 1px;
  width: var(--icon-width);
}

/* stylelint-disable-next-line selector-max-compound-selectors */
.meeting-agenda dd .agenda-item-name svg.lightning-icon {
  margin-top: 3px;
  rotate: 24deg;
}
