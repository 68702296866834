/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-max-compound-selectors */
@charset 'UTF-8';

ul.carousel,
ul.carousel li,
.top-app-bar ul,
.top-app-bar ul li {
  --column-gap: calc(var(--gutter) * 3);
}

@media (width <= 1024px) {
  ul.carousel,
  ul.carousel li,
  .top-app-bar ul,
  .top-app-bar ul li {
    --column-gap: calc(var(--gutter) * 2);
  }
}

ul.carousel,
.top-app-bar ul {
  --horizontal-padding: 5vw;

  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: var(--gutter) var(--column-gap);
  list-style: none;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  max-width: 1280px;
  padding-bottom: 0;
  padding-left: var(--horizontal-padding);
  padding-right: var(--horizontal-padding);
  padding-top: 0;
  place-content: center space-evenly;
  text-align: center;
}

ul.carousel li,
.top-app-bar ul li {
  align-self: auto;
  flex-basis: calc(100% / 3 - var(--column-gap));
  flex-grow: 1;
  flex-shrink: 1;
}

.top-app-bar ul {
  flex-wrap: wrap;
}

.top-app-bar li.brandmark-row {
  flex-basis: 100%;
  position: relative;
}

/* ----------------------------------------------- */
.carousel-item-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  place-content: flex-start flex-start;
  position: relative;
  row-gap: calc(var(--gutter) / 2);
}

.carousel-item-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

.carousel-item-media {
  --min-width: 152px;
  --max-width: 256px;
  --preferred-width: 100%;

  align-content: center;
  aspect-ratio: 1 / 1;
  background-color: var(--color-black);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  transform-origin: center center;
  width: clamp(var(--min-width), var(--preferred-width), var(--max-width));
}

/* ----------------------------------------------- */
.carousel-item-header,
.carousel-item-call-to-action {
  text-shadow: var(--text-shadow-black);
}

.carousel-item-header {
  padding-top: calc(var(--gutter) / 2);
}

.carousel h2 {
  --vertical-margin: 0;

  font-family: var(--font-family-code-bold);
  font-size: var(--font-size-h6-fluid);
}

.carousel-item-call-to-action {
  font-family: var(--font-family-code-regular);
  font-size: var(--font-size-body-small);
  line-height: var(--type-scale-golden-ratio);
}

/* ----------------------------------------------- */

.carousel-item-header a {
  color: var(--color-white);
  transition-duration: var(--speed-fast);
  transition-property: color;
  transition-timing-function: var(--easing-color-or-opacity);
}

a.carousel-item-media:hover::before,
.carousel-item-header a:hover {
  --highlight-color: var(--color-electric-blue-400);
}

.carousel-item-header a:hover,
.carousel-item-header a[data-is-active="true"] {
  color: var(--highlight-color);
  transition-duration: var(--speed-super-fast);
}

.carousel-item-header a:active,
.carousel-item-header a:focus {
  color: var(--color-electric-blue-100);
  transition-property: none;
}

/* ----------------------------------------------- */
a.carousel-item-media::before {
  --size: 100%;
  --position: 0;

  background-color: var(--color-midnight-blue-800);
  content: "";
  display: grid;
  height: var(--size);
  left: var(--position);
  place-content: center;
  position: absolute;
  top: var(--position);
  transform-origin: center center;
  transition-duration: var(--speed-normal);
  transition-property: background-color;
  transition-timing-function: var(--easing-color-or-opacity);
  width: var(--size);
  z-index: -1;
}

a.carousel-item-media:hover::before {
  --highlight-color: var(--color-electric-blue-400);

  background-color: var(--highlight-color);
  background-image: none;
  transition-duration: var(--speed-fast);
  transition-timing-function: var(--easing-entering);
}

ul.carousel li:nth-child(1) .carousel-item-media:hover::before,
ul.carousel li:nth-child(1) .carousel-item-header a:hover,
ul.carousel li:nth-child(1) .carousel-item-header a[data-is-active="true"] {
  --highlight-color: var(--color-lemon-yellow-500);
}

ul.carousel li:nth-child(3) .carousel-item-media:hover::before,
ul.carousel li:nth-child(3) .carousel-item-header a:hover,
ul.carousel li:nth-child(3) .carousel-item-header a[data-is-active="true"] {
  --highlight-color: var(--color-ruby-red-500);
}

a.carousel-item-media img,
a.carousel-item-media video {
  --media-size: calc(100% - var(--gutter) * 0.5);
  --media-position: calc(50% - var(--media-size) / 2);

  display: inline-flex;
  height: var(--media-size);
  left: var(--media-position);
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size);
}

/* ----------------------------------------------- */
@media (width <= 768px) {
  ul.carousel {
    --horizontal-padding: 0;

    flex-direction: column;
  }

  .carousel-item-container {
    display: block;
    text-align: left;
  }

  .carousel-item-cell {
    --media-width: 120px;

    display: block;
  }

  .carousel-item-media {
    /* stylelint-disable-next-line property-disallowed-list */
    float: left;
    margin-left: 0;
    margin-right: 0;
    width: var(--media-width);
  }

  .carousel-item-header,
  .carousel-item-call-to-action {
    margin-left: calc(var(--media-width) + var(--gutter));
  }

  .carousel-item-header {
    padding-bottom: calc(var(--gutter) / 2);
    padding-top: 0;
  }

  .carousel h2 {
    font-size: var(--font-size-h6-fluid);
  }

  .carousel-item-call-to-action {
    font-size: var(--font-size-body-fluid);
  }

  ul.carousel li {
    border-bottom: 3px solid var(--color-midnight-blue-500);
    padding-bottom: var(--gutter);
  }

  ul.carousel li:last-child {
    border-bottom: none;
  }
}

@media (width <= 540px) {
  .carousel-item-container {
    text-align: center;
  }

  .carousel-item-cell {
    --media-width: 180px;
  }

  .carousel-item-media {
    /* stylelint-disable-next-line property-disallowed-list */
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  .carousel-item-header,
  .carousel-item-call-to-action {
    margin-left: 0;
  }

  .carousel-item-header {
    padding-top: var(--gutter);
  }

  ul.carousel li {
    padding-left: var(--gutter);
    padding-right: var(--gutter);
  }
}
