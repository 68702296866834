@charset 'UTF-8';

.hero-illustration {
  aspect-ratio: 16 / 9;
  position: relative;
}

.hero-illustration.cityscape .city-background {
  fill: var(--color-electric-blue-700);
}

.hero-illustration.cityscape .city-foreground {
  fill: var(--color-electric-blue-400);
}

.hero-illustration.cityscape .jmf-logo {
  fill: var(--color-ruby-red-700);
}

/* ----------------------------------------------- */
.text-link-with-illustration,
.text-link-with-illustration svg {
  display: block;
}

.text-link-with-illustration {
  border-bottom: 4px solid var(--color-midnight-blue-500);
  display: block;
}

.text-link-with-illustration .label {
  --position-xy: 0;

  font-size: var(--font-size-h6-fluid);
  left: var(--position-xy);
  position: absolute;
  top: var(--position-xy);
  z-index: 1;
}
