@charset 'UTF-8';

nav.top-app-bar {
  margin-bottom: var(--gutter);
  margin-top: calc(var(--gutter) / 2);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  position: relative;
}

.top-app-bar a::before,
.top-app-bar ul,
.top-app-bar li {
  --font-size: var(--font-size-body-big-fluid);
}

.top-app-bar li.brandmark-row {
  flex-basis: 100%;
  position: relative;
}

.top-app-bar ul.nested-top-app-bar-menu,
.top-app-bar ul.nested-top-app-bar-menu li,
.top-app-bar ul.nested-top-app-bar-menu a::before,
.top-app-bar li.top-app-bar-utilities,
.top-app-bar li.top-app-bar-utilities a::before {
  --font-size: var(--font-size-body-small);
}

.top-app-bar li.top-app-bar-utilities,
.top-app-bar ul.nested-top-app-bar-menu,
.top-app-bar li.top-app-bar-utilities em,
.top-app-bar ul.nested-top-app-bar-menu em {
  font-family: var(--font-family-code-medium);
}

.top-app-bar li.top-app-bar-utilities {
  flex-basis: auto;
  flex-shrink: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.top-app-bar li.top-app-bar-utilities.home {
  left: 0;
  right: unset;
}

/* ----------------------------------------------- */
.top-app-bar .link,
.top-app-bar .link::after {
  --decoration-height: 2px;
}

.top-app-bar .link {
  color: var(--color-white);
  display: block;
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
  padding-bottom: var(--decoration-height);
  position: relative;
  text-decoration: none;
  text-shadow: var(--text-shadow-black);
}

.top-app-bar .top-app-bar-utilities .link,
.top-app-bar .top-app-bar-utilities .link::after {
  --decoration-height: 0;
}

.top-app-bar .top-app-bar-utilities .link {
  align-items: center;
  column-gap: calc(var(--font-size) / 2);
  display: inline-flex;
}

.top-app-bar .link:hover {
  text-shadow: none;
}

.top-app-bar .link[aria-current="page"] {
  color: var(--color-white);
}

.top-app-bar .link.primary-link {
  display: block;
  font-family: var(--font-family-code-medium);
  letter-spacing: var(--letter-spacing-all-caps);
  margin-left: auto;
  margin-right: auto;
  max-width: 200px;
  text-transform: uppercase;
  width: 100%;
}

@media (width <= 768px) {
  .top-app-bar ul {
    /* stylelint-disable-next-line declaration-no-important */
    --horizontal-padding: 0 !important;
  }
}

/* ----------------------------------------------- */

.top-app-bar .link::before {
  --height: calc(var(--font-size) * 2);
  --width: calc(100% + var(--font-size) * 1);
  --position-y: calc(50% - (var(--height) / 2));
  --position-x: calc(50% - (var(--width) / 2));

  background-color: var(--color-midnight-blue-700);
  border-color: transparent;
  border-radius: var(--rounded-corners-medium);
  border-style: solid;
  border-width: 2px;
  content: "";
  height: var(--height);
  left: var(--position-x);
  position: absolute;
  top: calc(var(--position-y) - var(--decoration-height) / 3);
  transition-duration: var(--speed-fast);
  transition-property: background-color;
  transition-timing-function: var(--easing-color-or-opacity);
  width: var(--width);
  z-index: -1;
}

.top-app-bar .top-app-bar-utilities .link::before {
  --height: calc(var(--font-size) * 2.4);

  background-color: var(--color-midnight-blue-500);
  border-width: 1px;
  box-shadow: var(--shadow-elevation-low);
}

.top-app-bar .link:hover::before,
.top-app-bar .link[aria-current="page"]::before {
  background-color: var(--color-black);
  box-shadow: var(--shadow-elevation-medium);
}

.top-app-bar .link:hover::before {
  box-shadow: var(--shadow-elevation-high);
}

.top-app-bar .link:active::before {
  box-shadow: var(--shadow-elevation-low);
}

.top-app-bar .link:active::before,
.top-app-bar .link:focus::before,
.top-app-bar .link[aria-current="page"]::before {
  border-color: var(--color-electric-blue-500);
  transition-property: none;
}

.top-app-bar .link.meeting:active::before,
.top-app-bar .link.meeting:focus::before,
.top-app-bar .link.meeting[aria-current="page"]::before {
  border-color: var(--color-lemon-yellow-500);
}

.top-app-bar .link.about:active::before,
.top-app-bar .link.about:focus::before,
.top-app-bar .link.about[aria-current="page"]::before {
  border-color: var(--color-ruby-red-700);
}

/* ----------------------------------------------- */
.brandmark {
  --brandmark-width: 168px;

  fill: var(--color-white);
  margin-left: auto;
  margin-right: auto;
  margin-top: calc(var(--gutter) / 4);
  overflow: visible;
  position: relative;
  width: var(--brandmark-width);
}

@media (width <= 768px) {
  .brandmark {
    --brandmark-width: 152px;
  }
}

.brandmark::after {
  --circuits-height: calc(var(--brandmark-width) / 5);
  --circuits-width: calc(var(--brandmark-width) / 1.75);

  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 85.3"><path d="M256 5.7c0 3.2-2.6 5.7-5.7 5.7s-4.9-1.9-5.6-4.4h-101l-35.6 26.9 3.2 4.6H192c.5-2.6 2.8-4.6 5.6-4.6s5.7 2.6 5.7 5.7-2.6 5.7-5.7 5.7-5.1-1.9-5.6-4.5l-80.7-.2-4.1-6-15.3 11.5h-1.7l7.2 10.5 55.5-.6c.4-2.8 2.8-4.9 5.7-4.9s5.7 2.6 5.7 5.7-2.6 5.7-5.7 5.7-5.3-2.1-5.7-4.9H98.1l14.2 20.7h103.1c.5-2.6 2.8-4.6 5.6-4.6s5.7 2.6 5.7 5.7-2.6 5.7-5.7 5.7-5.1-1.9-5.6-4.5H112.2L96.6 57.5H4l91.9-1L88.8 46 0 45.5l87.8-.7-10.2-15H4l74.6-.8 10.8 15.8h2.7l14.5-11.4L88.3 6.8H4.1l84.6-1 18.6 26.9 36.3-28.5h101.1c.6-2.5 2.9-4.3 5.5-4.3s5.7 2.6 5.7 5.7z" fill="%237ee1f1"/></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  bottom: calc(var(--circuits-height) / 1.6 * -1);
  content: "";
  height: var(--circuits-height);
  left: calc(var(--circuits-width) / 1.5 * -1);
  position: absolute;
  scale: 1.3;
  width: var(--circuits-width);
}

.brandmark .monoclonal-antibody {
  fill: var(--color-ruby-red-700);
}

.brandmark-row .descriptor {
  display: block;
  font-family: var(--font-family-code-semi-bold);
  font-size: var(--font-size-body-fluid);
  line-height: var(--type-scale-golden-ratio);
  margin-top: calc(var(--gutter) * 1.25);
  text-shadow: var(--text-shadow-black);
  white-space: nowrap;
}

.brandmark-row .descriptor,
/* stylelint-disable-next-line no-descending-specificity */
.brandmark-row .descriptor em {
  font-family: var(--font-family-code-semi-bold);
  font-style: normal;
}

@media (width <= 768px) {
  .top-app-bar ul {
    /* stylelint-disable-next-line declaration-no-important */
    --horizontal-padding: 0 !important;
  }
}

@media (width <= 480px) {
  .brandmark {
    --brandmark-width: 128px;
  }

  .brandmark::after {
    display: none;
  }

  .brandmark-row .descriptor em {
    display: block;
  }

  .brandmark-row .descriptor {
    margin-top: calc(var(--gutter) * 0.5);
  }
}

/* ----------------------------------------------- */
.top-app-bar em.icon {
  --icon-size: 24px;

  aspect-ratio: 1 / 1;
  background-color: var(--color-midnight-blue-900);
  border-radius: 100%;
  display: grid;
  height: var(--icon-size);
  overflow: hidden;
  padding: 4px;
  place-content: center center;
  position: relative;
  width: var(--icon-size);
}

.top-app-bar .link:hover em.icon,
.top-app-bar .link[aria-current="page"] em.icon {
  background-color: var(--color-electric-blue-400);
  color: var(--color-black);
}

.top-app-bar em.icon svg {
  --size: 100%;

  display: block;
  fill: currentcolor;
  height: var(--size);
  margin-left: auto;
  margin-right: auto;
  width: var(--size);
}

/* ----------------------------------------------- */
@media (width <= 768px) {
  .top-app-bar li.top-app-bar-utilities {
    margin-right: calc(var(--gutter) / 2);
  }

  .top-app-bar li.top-app-bar-utilities.home {
    margin-left: calc(var(--gutter) / 2);
    margin-right: 0;
  }
}

@media (width <= 540px) {
  .top-app-bar ul:not(.nested-top-app-bar-menu) {
    --column-gap: calc(var(--gutter) * 1.5);

    justify-content: center;
  }

  .top-app-bar li[data-page="home"] {
    display: none;
  }

  .top-app-bar li.top-app-bar-utilities .icon {
    display: unset;
  }

  .top-app-bar li.top-app-bar-utilities .label {
    display: none;
  }
}

@media (width <= 480px) {
  .top-app-bar ul {
    row-gap: calc(var(--gutter) * 0.75);
  }

  nav.top-app-bar {
    --horizontal-padding: calc(var(--gutter) / 4);
  }

  .top-app-bar li.top-app-bar-utilities {
    right: calc(var(--gutter) / 4);
  }

  .top-app-bar li.top-app-bar-utilities.home {
    left: calc(var(--gutter) / 4);
  }
}

/* ----------------------------------------------- */
.top-app-bar ul.nested-top-app-bar-menu {
  align-items: center;
  column-gap: var(--gutter);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-code-medium);
  font-size: var(--font-size);
  margin-bottom: unset;
  margin-left: unset;
  margin-right: unset;
  margin-top: unset;
  max-width: unset;
  place-content: center flex-end;
}

.top-app-bar ul.nested-top-app-bar-menu li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.top-app-bar ul {
  font-size: var(--font-size);
}
