@charset 'UTF-8';

.countdown-to-annual-meeting,
.countdown-to-annual-meeting .hero-illustration {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.countdown-to-annual-meeting {
  --horizontal-ruler-height: 1px;

  border-top: var(--horizontal-ruler-height) solid var(--color-electric-blue-700);
  margin-top: calc(var(--gutter) * 1.5 + var(--horizontal-ruler-height));
  padding-top: calc(var(--gutter) * 1.25);
}

.countdown-to-annual-meeting h2 {
  margin-top: 0;
}

.countdown-to-annual-meeting .hero-illustration {
  max-width: 768px;
}
