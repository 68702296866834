@charset 'UTF-8';

[data-shape="rabbet"] {
  clip-path: polygon(
    0% 15%,
    15% 15%,
    15% 0%,
    85% 0%,
    85% 15%,
    100% 15%,
    100% 85%,
    85% 85%,
    85% 100%,
    15% 100%,
    15% 85%,
    0% 85%
  );
}

[data-shape="triangle"] {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

[data-shape="rhombus"] {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

[data-shape="circle"] {
  clip-path: circle(50% at 50% 50%);
}

[data-shape="message"] {
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
}

[data-shape="nonagon"] {
  clip-path: polygon(
    50% 0%,
    83% 12%,
    100% 43%,
    94% 78%,
    68% 100%,
    32% 100%,
    6% 78%,
    0% 43%,
    17% 12%
  );
}

[data-shape="bevel"] {
  clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
}

[data-shape="right-point"] {
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
}

[data-shape="trapezoid"] {
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
}

[data-shape="dna-sequence-paper"] {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 10% 90%, 90% 90%, 90% 10%, 10% 10%);
}

[data-shape="book"] {
  clip-path: polygon(0% 0%, 50% 5%, 100% 0%, 100% 100%, 50% 95%, 0% 100%);
}

[data-shape="virus-bacteria"] {
  clip-path: polygon(
    50% 0%,
    58% 18%,
    75% 15%,
    68% 33%,
    85% 50%,
    68% 67%,
    75% 85%,
    58% 82%,
    50% 100%,
    42% 82%,
    25% 85%,
    32% 67%,
    15% 50%,
    32% 33%,
    25% 15%,
    42% 18%
  );
}

[data-shape="dna-double-helix"] {
  clip-path: polygon(
    50% 0%,
    55% 10%,
    60% 0%,
    65% 10%,
    70% 0%,
    75% 10%,
    80% 0%,
    85% 10%,
    90% 0%,
    95% 10%,
    100% 0%,
    100% 100%,
    95% 90%,
    90% 100%,
    85% 90%,
    80% 100%,
    75% 90%,
    70% 100%,
    65% 90%,
    60% 100%,
    55% 90%,
    50% 100%,
    45% 90%,
    40% 100%,
    35% 90%,
    30% 100%,
    25% 90%,
    20% 100%,
    15% 90%,
    10% 100%,
    5% 90%,
    0% 100%,
    0% 0%,
    5% 10%,
    10% 0%,
    15% 10%,
    20% 0%,
    25% 10%,
    30% 0%,
    35% 10%,
    40% 0%,
    45% 10%
  );
}

[data-shape="antibody"] {
  clip-path: polygon(50% 0%, 55% 20%, 70% 20%, 65% 50%, 50% 40%, 35% 50%, 30% 20%, 45% 20%);
}

[data-shape="cell"] {
  clip-path: polygon(
    50% 0%,
    60% 10%,
    70% 0%,
    80% 10%,
    90% 0%,
    100% 10%,
    90% 20%,
    100% 30%,
    90% 40%,
    100% 50%,
    90% 60%,
    100% 70%,
    90% 80%,
    100% 90%,
    90% 100%,
    80% 90%,
    70% 100%,
    60% 90%,
    50% 100%,
    40% 90%,
    30% 100%,
    20% 90%,
    10% 100%,
    0% 90%,
    10% 80%,
    0% 70%,
    10% 60%,
    0% 50%,
    10% 40%,
    0% 30%,
    10% 20%,
    0% 10%,
    10% 0%,
    20% 10%,
    30% 0%,
    40% 10%
  );
}

[data-shape="petri-dish"] {
  clip-path: polygon(50% 0%, 80% 20%, 100% 50%, 80% 80%, 50% 100%, 20% 80%, 0% 50%, 20% 20%);
}

[data-shape="microscopic-slide"] {
  clip-path: polygon(
    10% 0%,
    90% 0%,
    100% 10%,
    100% 90%,
    90% 100%,
    10% 100%,
    0% 90%,
    0% 10%,
    50% 50%
  );
}

[data-shape="microscope"] {
  clip-path: polygon(
    0% 0%,
    30% 0%,
    30% 40%,
    70% 40%,
    70% 0%,
    100% 0%,
    100% 70%,
    70% 70%,
    70% 100%,
    30% 100%,
    30% 70%,
    0% 70%
  );
}

[data-shape="antigen"] {
  clip-path: polygon(
    50% 0%,
    65% 10%,
    80% 0%,
    95% 10%,
    100% 30%,
    80% 50%,
    100% 70%,
    95% 90%,
    80% 100%,
    65% 90%,
    50% 100%,
    35% 90%,
    20% 100%,
    5% 90%,
    0% 70%,
    20% 50%,
    0% 30%,
    5% 10%,
    20% 0%,
    35% 10%
  );
}

[data-shape="chromosome"] {
  clip-path: polygon(
    20% 0%,
    30% 20%,
    40% 10%,
    50% 20%,
    60% 10%,
    70% 20%,
    80% 0%,
    90% 10%,
    80% 30%,
    70% 50%,
    80% 70%,
    90% 90%,
    80% 100%,
    70% 80%,
    60% 90%,
    50% 80%,
    40% 90%,
    30% 80%,
    20% 100%,
    10% 90%,
    20% 70%,
    30% 50%,
    20% 30%,
    10% 10%
  );
}

[data-shape="microscope-slide-detailed-rectangle"] {
  clip-path: polygon(
    10% 0%,
    90% 0%,
    90% 10%,
    80% 10%,
    80% 20%,
    90% 20%,
    90% 80%,
    80% 80%,
    80% 90%,
    90% 90%,
    90% 100%,
    10% 100%,
    10% 90%,
    20% 90%,
    20% 80%,
    10% 80%,
    10% 20%,
    20% 20%,
    20% 10%,
    10% 10%
  );
}

[data-shape="gene-segment"] {
  clip-path: polygon(
    10% 0%,
    90% 0%,
    90% 20%,
    80% 20%,
    80% 40%,
    90% 40%,
    90% 60%,
    80% 60%,
    80% 80%,
    90% 80%,
    90% 100%,
    10% 100%,
    10% 80%,
    20% 80%,
    20% 60%,
    10% 60%,
    10% 40%,
    20% 40%,
    20% 20%,
    10% 20%
  );
}

[data-shape="petri-dish-circle-with-sectors"] {
  clip-path: polygon(
    50% 0%,
    65% 15%,
    80% 0%,
    95% 15%,
    100% 50%,
    85% 65%,
    100% 80%,
    85% 95%,
    50% 100%,
    35% 85%,
    20% 100%,
    5% 85%,
    0% 50%,
    15% 35%,
    0% 20%,
    15% 5%,
    50% 0%
  );
}

[data-shape="virus-complex-spiky-circle"] {
  clip-path: polygon(
    50% 0%,
    55% 10%,
    65% 5%,
    70% 15%,
    80% 10%,
    85% 20%,
    95% 15%,
    100% 25%,
    90% 35%,
    95% 45%,
    85% 50%,
    90% 60%,
    80% 65%,
    85% 75%,
    70% 80%,
    75% 90%,
    60% 85%,
    65% 95%,
    50% 100%,
    45% 90%,
    35% 95%,
    30% 85%,
    20% 90%,
    15% 80%,
    5% 75%,
    10% 65%,
    0% 60%,
    10% 50%,
    0% 45%,
    10% 35%,
    0% 25%,
    15% 20%,
    5% 15%,
    20% 10%,
    15% 5%,
    30% 10%,
    25% 0%
  );
}

[data-shape="neural-network"] {
  clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
}

[data-shape="chip"] {
  clip-path: polygon(25% 0%, 75% 0%, 100% 25%, 100% 75%, 75% 100%, 25% 100%, 0% 75%, 0% 25%);
}

[data-shape="ai-circuit"] {
  clip-path: polygon(
    25% 0%,
    75% 0%,
    75% 25%,
    100% 25%,
    100% 75%,
    75% 75%,
    75% 100%,
    25% 100%,
    25% 75%,
    0% 75%,
    0% 25%,
    25% 25%
  );
}

[data-shape="brain"] {
  clip-path: polygon(
    50% 0%,
    70% 10%,
    80% 20%,
    90% 40%,
    80% 60%,
    70% 80%,
    50% 100%,
    30% 80%,
    20% 60%,
    10% 40%,
    20% 20%,
    30% 10%
  );
}

[data-shape="graph-network"] {
  clip-path: polygon(
    10% 10%,
    30% 10%,
    30% 30%,
    50% 30%,
    50% 10%,
    70% 10%,
    70% 50%,
    90% 50%,
    90% 70%,
    70% 70%,
    70% 90%,
    30% 90%,
    30% 70%,
    10% 70%,
    10% 30%,
    30% 30%
  );
}
